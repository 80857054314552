@media only screen and ( max-width: 768px ){
	body{
		@include font-size( 15px );
	}
	h1{
		@include font-size( 26px );
	}
	.section.expertise-payoff .section-title, h2{
		@include font-size( 20px );
	}
	h3, .section.cta-section .column.gf-column .column-title{
		@include font-size( 15px );
	}
	h4{
		@include font-size( 15px );
	}
	h5{
		@include font-size( 14px );
	}
	h6{
		@include font-size( 13px );
	}
	.intro p{
		@include font-size( 17px );
	}
	.column.featured-column .repeater-item{
		padding:15px;
		margin-bottom:30px;
	}

	#main .section:first-child .section-wrapper{
		padding-top:30px;
	}

	#main .section .section-wrapper{
		padding:0 0 30px 0;
	}

	.section.cta-section .column.gf-column, .section.cta-section .column.content:last-child{
		margin:30px 0;
		.inner-container{
			padding:15px;
		}
	}
	.column.gf-column input[type=text], .column.gf-column textarea{
		@include font-size( 15px );
	}
	.collection.blocks .block.block-expertises .thumb img{
		max-width:110px;
	}
	.button, .btn, a[class^="btn--"], .button:visited, .btn:visited, a[class^="btn--"]:visited{
		@include font-size( 15px );
	}
}