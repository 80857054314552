@media only screen and ( max-width: 1024px ){

	.column.slider-column .main-gallery{
		&.images-5{
			.gallery-cell{
				width:23.5%;
			}
		}
	}

}

@media only screen and ( max-width: 1024px ){

	.column-wrapper{

		&.four-columns .column{
			flex:0 0 49%;
			margin-right:2%;
			&:nth-child(1), &:nth-child(2){
				margin-bottom:20px;
			}
			&:nth-child(even){
				margin-right:0;
			}
		}
	}

	.collection.gallery .gallery-row { 
		flex-flow: row wrap;
		margin-bottom: 0;
		.gallery-item, .gallery-item:visited{
			flex: 49%;
			max-width: 49%;
			margin: 0 2% 20px 0;

			&:nth-of-type(even){
				margin-right: 0;
			}
		}
	}

	.column.slider-column .main-gallery{
		&.images-4, &.images-5{
			.gallery-cell{
				width:32.5%;
			}
		}
	}

}

@media only screen and ( max-width: 980px ){
	.section .column-wrapper{ 
		&.sidebar-left .column, &.sidebar-right .column{
			flex:1;
		}
	}

}

@media only screen and ( max-width: 850px ){

	.column-wrapper.three-columns .column{
		flex:0 0 100%;
		margin:0 0 30px 0;
		&:last-child{
			margin-bottom:0;
		}
	}

}

@media only screen and ( max-width: 800px ){

	.column.slider-column .main-gallery{
		&.images-3, &.images-4, &.images-5{
			.gallery-cell{
				width:49%;
			}
		}
	}
}

@media only screen and ( max-width: 768px ){

	.column-row{
		> *{
			margin-bottom:30px;

			&:last-child{
				margin-bottom:0 !important;
			}
		}
	}

	.section .column-wrapper{

		&.half-half .column, &.four-columns .column, &.sidebar-left .column, &.sidebar-right .column{
			flex:0 0 100%;
			margin-right:0;
			&:nth-child(even){
				margin-right:0;
			}
		}
	}

	.section .filter-column{
		order: -1;
		margin-bottom:30px !important;
	}

	.column-wrapper{
		.column{
			margin-bottom: 30px;

			&:last-of-type{
				margin-bottom: 0;
			}
		}
	}

	.column-wrapper.four-columns{
		.column{
			margin-bottom: 30px;

			&:nth-child(1), &:nth-child(2){
				margin-bottom:30px;
			}

			&:last-of-type{
				margin-bottom: 0;
			}
		}
	}

	.slider-column .main-gallery .gallery-cell{
		width: 32%;
	}
}

@media only screen and ( max-width: 767px ){

	.form .form-fields .field-row .field-wrapper{
		flex:0 0 100%;
		margin:0 0 15px 0;
	}

}

@media only screen and ( max-width: 600px ){

	.column.slider-column .main-gallery .gallery-cell{
		width:100%;
	}

	.column.slider-column .main-gallery{
		&.images-2, &.images-3, &.images-4, &.images-5{
			.gallery-cell{
				width:100%;
			}
		}
	}

	.slider-column .main-gallery .gallery-cell{
		width: 49%;
	}
}

@media only screen and ( max-width: 480px ){
	.column.slider-column .main-gallery{
		padding:0;
	}
	.column.slider-column .flickity-prev-next-button{
		display: none;
	}
	.column.slider-column .main-gallery.nav-arrows-true{
		padding:0;
	}

	.collection.gallery .gallery-row { 
		flex: none;
		display: block;
		.gallery-item, .gallery-item:visited{
			flex: none;
			display: block;
			max-width: none;
			width: 100%;
			margin: 0 0 15px 0;
		}
	}
	.section .five-columns{
		flex: none;
		display: block;
		
		.column{
			flex: none;
			display: block;
			width: 100%;
			max-width: 100%;
			margin: 0 0 15px 0;
		}
	}
}

@media only screen and ( max-width: 400px ){
	.slider-column .main-gallery .gallery-cell{
		width: 100%;
	}
	.share-buttons .share-text{
		display: block;
		margin: 0 0 15px 0;
	}
}