@media only screen and ( max-width: 1220px ){

	header .logo{
		left:4%;
	}

}

@media only screen and ( max-width: 1120px ){
	.big-header .container{
		min-height: 500px;
	}
	.big-header{
		background-attachment:scroll;
	}
}

@media only screen and ( max-width: 1024px ){

	.menu-switch{
		box-sizing:border-box;
		background:#FFF;
		i{
			color:$darkBlue;
		}
		.mobile-button-text{
			color:#FFF;
			left:-48px;
		}
	}

	header .container {
    	min-height: 65px;
	}

	header{
		background:$darkBlue;
	}

	header .logo, header.scrolled-state .logo{
		max-width: 145px;
		top: 0px;
		background:$lightBlue;
		padding:10px;
		height: 80px;
		overflow: hidden;
	}

	.mobile-nav ul li a, .mobile-nav ul li a:visited{
		box-sizing:border-box;
	}

	.big-header{
		display: flex;
		flex-flow:row wrap;
		align-items:flex-end;
		min-height:400px;
		.container{
			min-height:auto;
		}
	}

	.big-header .container{
		align-items:inherit;
		.left-side{
			flex:0 0 100%;
			align-self:inherit;
		}
		.right-side{
			flex:0 0 100%;
			order:-1;
			margin-bottom:30px;
			.payoff-area{
				margin-left:0;
			}
		}
	}

}

@media only screen and ( max-width: 980px ){

	.big-header .container .right-side .payoff-area{
		padding:30px 0px 40px 30px;
		@include font-size( 38px );
	}

	.big-header .container .left-side .header-btn, .big-header .container .left-side .header-btn:visited{
		@include font-size( 16px );
	}

}

@media only screen and ( max-width: 768px ){

	.big-header .container .right-side .payoff-area{
		padding:15px 0px 20px 15px;
		@include font-size( 30px );
	}

	.big-header .container .left-side .header-btn, .big-header .container .left-side .header-btn:visited{
		@include font-size( 14px );
		padding:10px 80px 10px 15px;
		&:before{
			width:50px;
		}
		&:after{
			width:50px;
		}
	}
	.big-header{
		min-height:300px;
	}

}