.footer-section{
	background:$darkBlue;
	h1, h2, h3, h4, h5, h6, p{
		color:#FFF;
	}

	.column.gf-column input[type=text], .column.gf-column textarea{
		background:none;
		border-color:#FFF;
		color:#FFF;
		@include placeholder(){
			color:#FFF;
		}
	}

	.column.content{
		a, a:visited{
			color:#FFF;
			&:hover{
				color:$lightBlue;
			}
		}
	}

	.column .button{
		&:hover, &:visited{
			background:$yellow;
			color:$darkBlue;
		}
	}
}

.footer .copyright{
	background:$lightGrey;
	color:$darkBlue;
	a, a:visited, span{
		color:$darkBlue;
	}
	.privacy-policy, .privacy-policy:visited{
		float:right;
		transition:.3s;
		&:hover{
			color:$lightBlue;
		}
	}
}

a[class^="mustache"]{
	background-position:bottom center;
	transition:.3s;
}