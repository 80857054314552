/**
 * Header CSS file 
 * Styling for the header:
 */


header{
	width: 100%;
	background: $white;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 999;
	border-bottom: 2px solid $grey;
	
	.container{
		min-height:78px;
		position: relative;
	}

	.logo{
		padding: 0;
		text-decoration: none;
		position: absolute;
		left: 0;
		top:50%;
		transform: translateY(-50% );
		z-index: 2;
		max-width:160px;
		display: block;
		width:100%;

		img{
			display: block;
			width: 100%;
			height: auto;
		}
	}
}

#main{
	padding-top: 98px; //account for sticky menu
}

header{
	.container{
		min-height:auto;
	}
	
	.main-nav{
		text-align:center;
		display: block;
		width:100%;
		
		ul{
			padding-left:0;
			list-style:none;
			
			li{
				display: inline-block;
				
				a, a:visited{
					padding:15px 25px;
					display: inline-block;
					text-decoration:none;
					transition: .3s;
					line-height:1;
				}
			}
		}
	}
}

.big-header{
	background-size:cover;
	background-position:center center;
	
	.container{
		min-height:400px;
	}
}

.mobile-nav{
	top: 150px;
    height: calc(100vh - 150px);
    overflow-y: scroll;
    width: 100%;
    position: fixed;
    right: 0%;
    left: 0;
    padding: 0;
    opacity: 0;
    transition:.3s;
    z-index: 900;
    text-align:center;
    pointer-events:none;
    display: none;
    ul{
		list-style: none;
		display: block;
		padding-left: 0;
		width: 100%;
		li{
			display: block;
			width: 100%;
			a, a:visited{
				width:100%;
				display: block;
				padding:10px 20px;
				text-decoration:none;
				color:#FFF;
			}
			&.current-menu-item, &.current-page-parent{
				a, a:visited{
					background:#FFF;
					color:$black;
				}
			}
		}
    }
    & > ul {
	    //margin: 50px 0;
	}
	&.fold-out{
		opacity:1;
		pointer-events:auto;
	}
}

.menu-switch{
	top: 50%;
    height: 44px;
    width:44px;
    text-align:center;
    margin-top:-22px;
    right:4%;
    border-radius: 0;
    padding-top: 12px;
    z-index: 100;
    background: $black;
    position:absolute;
    display: none;
    .mobile-button-text{
		position: absolute;
		left: -42px;
		color: $black;
		font-weight: 400;
		text-transform: none;
		font-size: 14px;
    }
    i{
    	color:#FFF;
    	font-size:18px;
    	position:relative;
    	z-index: 2;
    }
}

.background-wrapper{
	position:fixed;
	z-index: 800;
	top:0;
	left:0;
	right:0;
	bottom:0;
	background:none;
	pointer-events:none;
	opacity:0;
	background:$black;
	transition: .3s;
	display:none;
	&.active-bg{
		opacity:1;
		pointer-events:auto;
	}

}