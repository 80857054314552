/**
* General CSS file 
* Styling for all general classes:
* 
*/


// Reset all

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}

article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

html, body{
	margin:0;
	padding:0;
}

body{
	background:#fff;
	font-size:16px;
	color:$black;
	font-family:$font-stack;
}

/**
 * General Classes:
 */

	/* Increase frame-rate when scrolling by not registering pointer-events:*/
	.disable-hover, .disable-hover * { pointer-events: none !important; }

	/* Mustache link: */
	a[class^="mustache"]{display:block;;cursor:pointer;text-transform:uppercase;float:none;position:absolute;background:url('../images/mustache.png');width:80px;height:15px;left:50%;margin-left:-40px;top:5px;opacity:1;}
	a[class^="mustache"]:hover{opacity:.5;}
	a.mustache--black{background-position:0 20px;}

	/* Screenreader text support: */
	.screen-reader-text {
		clip: rect(1px, 1px, 1px, 1px);
		position: absolute !important;
		height: 1px;
		width: 1px;
		overflow: hidden;
	
		&:focus{
			background-color: #f1f1f1;
			border-radius: 3px;
			clip: auto !important;
			color: #21759b;
			display: block;
			font-size: rem( 14px );
			height: auto;
			left: 5px;
			padding: 15px 23px 14px;
			top: 5px;
			width: auto;
			z-index: 100000; /* Above WP toolbar. */
		}
	}

// Column styling
.column-wrapper{
	display: flex;
	flex-flow: row wrap;
}

.column-wrapper .column, .column-wrapper .collection{
	flex: 1;
	margin-right:30px;
	box-sizing:border-box;
	&:last-child{
		margin-right:0;
	}
}

img{
	max-width:100%;
	display: block;
	margin:0 auto;
	height:auto;
}

.column-wrapper.sidebar-left .column, .column-wrapper.sidebar-left .collection{
	flex:1;
	&:last-child{
		flex:2;
	}
}

.column-wrapper.sidebar-right .column, .column-wrapper.sidebar-right .collection{
	flex:1;
	&:first-child{
		flex:2;
	}
}

.column-wrapper.fullwidth{  
	justify-content: center;
	.column{
		flex:0 1 770px;
	}
}

.section .section-wrapper{
	padding:50px 0;
}

#main{
	.section{
		.section-wrapper{
			padding:0 0 50px 0;

		}
		&:first-child{
			.section-wrapper{
				padding-top:50px;
			}
		}
	}
}

.container{
	max-width:1170px;
	margin:0 auto;
}

body{
	font-family:$font-stack;
	font-size:16px;
}

.pages{
	text-align:center;
	display:block;
	width:100%;
	margin-top:20px;
	span, a, a:visited{
		width:44px;
		height:44px;
		background:$black;
		color:#FFF;
		display: inline-block;
		margin:10px 10px 0 0;
		text-align:center;
		text-decoration:none;
		padding-top:15px;
		box-sizing: border-box;
		line-height:1;
	}
	span{
		background:$grey;
		color:$black;
	}
}