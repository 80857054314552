/**
* Footer CSS file 
* Styling for the footer:
* 
*/

.footer{
	
	.copyright{
		padding:20px 0;
		background:$black;
		color:#FFF;
		a, a:visited, span{
			color:#FFF;
		}
		
		.container{
			position:relative;
			
			.chef-logo{
				position:absolute;
				right:0;
				top:50%;
				transform: translateY( -50% );
				img{
					display: block;
					max-width:100%;
					margin:0 auto;
				}
			}
		}
	}
}