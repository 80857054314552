/**
* Forms CSS file 
* Styling for forms:
* 
*/


.form-column .form{
	padding:0;
}

.form{
	.form-fields{
		.field-wrapper{
			position: relative;
			& > label{
				font-size:16px;
				margin-bottom:10px;
				display: block;
				width:100%;
			}
			&.radio{
				margin-top:5px;
				input{
					display: none;
				}
				.subfield-wrapper{ 
					display: block;
					margin-bottom:5px;
					label{
						display: inline-block;
						cursor: pointer;
						position: relative;
						padding-left: 30px;
						margin-right: 15px;
						top:-4px;

						&:hover{
							&:before{
								background: darken( $form-input-color, 5% );
							}
						}
					}
				}
				.subfield-wrapper label:before {
				    content: "";
				    display: inline-block;
				    width: 20px;
				    height: 20px;
				    text-align:center;
				 	transition: .3s;
				    margin-right: 10px;
				    position: absolute;
				    left: 0;
				    bottom: 0px;
				    background: $form-input-color;
				    border-radius:50%;
				}
				.subfield-wrapper label:after {
				    content: "";
				    display: inline-block;
				    width: 10px;
				    height: 10px;
				 	transition: .3s;
				    position: absolute;
				    left: 5px;
				    bottom: 5px;
				    background: $black;
				    border-radius:50%;
				    opacity:0;
				}
				input[type=radio]:checked + label:after {
				    opacity:1;
				}
			}
			&.checkbox, &.checkboxes{
				input[type=checkbox] {
				    display: none;
				}
				input[type=checkbox]:checked + label:after {
				    opacity:1;
				}
				label{
					display: inline-block;
					cursor: pointer;
					position: relative;
					padding-left: 30px;
					margin-right: 15px;
					margin-bottom:10px;

					&:hover{
						&:before{
							background: darken( $form-input-color, 5% );
						}
					}
				}
				label:before {
				    content: "";
				    display: inline-block;
				 	transition: .3s;
				    width: 20px;
				    height: 20px;
				    margin-right: 10px;
				    position: absolute;
				    left: 0;
				    bottom: 0;
				    background: $form-input-color;
				}
				label:after {
				    content: "\2713";
				 	transition: .3s;
				    width: 20px;
				    height: 20px;
				    position: absolute;
				    left: 0;
				    bottom:0px;
				    padding-bottom:8px;
				    text-align:center;
				    display: inline-block;
				    color: $black;
				    opacity:0;
				    font-size:30px;
				    line-height:100%;
				}
				
			}

			&.checkboxes{
				& > label{
					display: block;
					margin:0 0 10px 0;
					padding:0;
					cursor: auto;
					clip: none;
					position: relative !important;
					height: auto;
					width: auto;
					overflow: visible;
					&:before{
						display:none;
					}
				}
			}
		}
	}
}

.form .form-fields .field-wrapper .subfield-wrapper label{
	font-size:14px;
}

.form .form-fields .field-wrapper .subfield-wrapper{
	display:block;
	width:100%;
}

.select{
	position: relative;
	z-index: 2;
	cursor: pointer;
	position: relative;
	select{
		cursor: pointer;
		-webkit-appearance: none;
		-webkit-border-radius: 0px;
		background: #FFF;
		width:100%;

		&:focus{
			outline:none;
		}
	}
	&:after{
		content:"";
		height:40px;
		width:44px;
		pointer-events:none;
		display: block;
		position: absolute;
		background: $black;
		bottom:0;
		right:0;
		transition: .3s;

	}
	&:before{
		content:"\f078";
		position: absolute;
		right:0;
		width:44px;
		bottom:14px;
		color: $white;
		text-align:center;
		font-family: fontAwesome;
		font-size:12px;
		pointer-events:none;
		z-index: 2;
	}

	&:hover{
		&:after{
			background: $form-input-color;
		}
	}
}

//Some basic styling for the datepicker
.ui-datepicker{
	width:250px;
	background:#FFF;
	z-index: 10;
	border:1px solid $form-input-color;
	padding:5px;
	box-shadow:0 0 5px darken($form-input-color, 5%);
	display: none;
	a, a:visited{
		text-decoration:none;
	}
	.ui-datepicker-header{
		margin-bottom:5px;
	}
	.ui-datepicker-next{
		float:right;
	}
	.ui-datepicker-prev, .ui-datepicker-next{
		margin-bottom:10px;
		cursor: pointer;
		span{
			transition: .3s;
		}
	}
		
	.ui-state-default{
		color: $black;
		transition: .3s;
	}
}

// Basic layout
.form .form-fields{
	.field-row{
		display: flex;
		flex-flow: row wrap;
		align-items: flex-start;
		.field-wrapper{
			margin-bottom:15px;
			flex:1;
			textarea, input, select{
				width:100%;
				padding:8px 12px;
				box-sizing:border-box;
				border: 1px solid $form-input-color;
				font-size:16px;
				background-size: 16px auto;
				background-repeat: no-repeat;
				background-position: 97.5% center;
				@include placeholder(){
					font-size:16px;
				}
				&.validated-false{
					&:focus{
						border:1px solid $errorColor;
					}
				}
				&:focus{
					outline:none;
					& + .validation-error{
						opacity:1;
					}
				}
			}
			textarea{
				height:100px;
				background-position: 97.5% 48%;
			}
			.validation-error{
				background:$errorColor;
				padding:4px 8px;
				color: #FFF;
				position: absolute;
				top:-32px;
				right:0;
				opacity:0;
				pointer-events:none;
				transition: .3s;

				&:after{
					content:"";
					position: absolute;
					bottom:-10px;
					width:0;
					left:50%;
					transform: translateX(-50%);

					border-left:10px solid transparent;
					border-right:10px solid transparent;
					border-top:10px solid $errorColor;

				}
			}
		}
		.field-wrapper{
			margin-right:15px;
		}
		.field-wrapper:last-of-type{
			margin-right:0;
		}
	}
}

.form-fields .field-row .field-wrapper input.validated-true, .form-fields .field-row .field-wrapper textarea.validated-true, .form-fields .field-row .field-wrapper select.validated-true{
	background-image: url(../images/ok.png);
}

.form-fields .field-row .field-wrapper input.validated-false, .form-fields .field-row .field-wrapper textarea.validated-false, .form-fields .field-row .field-wrapper select.validated-false{
	background-image: url(../images/fail.png);
}

.form-footer .submit-form{
	cursor:pointer;
}

.form .loader{
	position: absolute;
    display: none;
    pointer-events: none;
	top:50%;
	left:50%;
	transform:translateX( -50% ) translateY( -50% );
    width: 140px;
    z-index: 2;
}

.form{
	position:relative;
}

.loader {
	width: 70px;
	text-align: center;
}

.form{ 
	&.active{
		.loader{
			display:block;
			pointer-events:auto;
		}
	}
}

.loader > div {
	width: 18px;
	height: 18px;
	border-radius: 100%;
	display: inline-block;
	-webkit-animation: bouncedelay 1.4s infinite ease-in-out;
	animation: bouncedelay 1.4s infinite ease-in-out;
	/* Prevent first frame from flickering when animation starts */
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
}

.loader .bounce1 {
	-webkit-animation-delay: -0.32s;
	animation-delay: -0.32s;
}

.loader .bounce2 {
	-webkit-animation-delay: -0.16s;
	animation-delay: -0.16s;
}

@-webkit-keyframes bouncedelay {
	0%, 80%, 100% { -webkit-transform: scale(0.0) }
	40% { -webkit-transform: scale(1.0) }
}

@keyframes bouncedelay {
	0%, 80%, 100% { 
		transform: scale(0.0);
		-webkit-transform: scale(0.0);
	} 40% { 
		transform: scale(1.0);
		-webkit-transform: scale(1.0);
	}
}

.form .loader {
  position: absolute;
  display: none;
  pointer-events: none;
  top: 50%;
  left: 50%;
  right: 0;
  z-index: 2;
  transform:translateY( -50% ) translateX( -50% );
}
.form .loader > div {
  width: 24px;
  height: 24px;
}

.form{
	position:relative;
}

.form.msg:before{
	content: "";
	position: absolute;
	z-index: 100;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	height: 100%;
	width: 100%;
	z-index: 100;
	background: rgba(255, 255, 255, 0.7);
}

.form .message {
	position: absolute;
	padding: 30px;
	font-size: 20px;
	width: 270px;
	left: 50%;
	margin-left: -135px;
	top: 50%;
	z-index: 105;
	transform:translateY( -50% );
}
