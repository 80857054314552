@media only screen and ( max-width: 1480px ){

	.section.expertise-payoff .column .column-title{
		@include font-size( 12px );
	}

	.section.expertise-payoff .container{
		max-width:1280px;
	}

}

@media only screen and ( max-width: 1320px ){

	.section.expertise-payoff .container{
		max-width:1170px;
	}

}

@media only screen and ( max-width: 1220px ){

	.section.expertise-payoff .column:after{
		display:none;
	}

}

@media only screen and ( max-width: 1024px ){

	.section.expertise-payoff .column{
		flex:0 1 50%;
		margin:0 !important;
	}
}

@media only screen and ( max-width: 768px ){
	.section.cta-section:before{
		display:none;
	}
	.section.cta-section .column.content:first-child{
		padding:60px 15px;
		background-image: url(../images/cta-bg.jpg);
		background-size: cover;
		background-position: center center;
	}
}

@media only screen and ( max-width: 600px ){

	.collection.blocks .block.big-expertises{
		.thumb{
			flex: 0 0 100%;
			order:-1 !important;
			margin:0 0 15px 0 !important;
		}
		.content-wrapper{
			flex: 0 0 100%;
			margin:0;
		}
	}

}