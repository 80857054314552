.column.featured-column{
	.repeater-item{
		background:$yellow;
		padding:30px;
		margin-bottom:15px;
		border-radius:$border-radius;
		.featured-title{
			color:$lightBlue;
			text-transform:uppercase;
			font-weight:$bold-weight;
			margin-bottom:5px;
		}
		.featured-text{
			color:$darkBlue;
		}
		&:last-child{
			margin-bottom:0;
		}
		&:nth-child(even){
			background:$darkBlue;
			.featured-title{
				color:$lightBlue;
			}
			.featured-text{
				color:$yellow;
			}
		}
	}

}