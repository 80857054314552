.collection.blocks{
	.block.block-expertises{
		box-shadow:0 0 0 2px $lightGrey;
		
		box-sizing:border-box;
		position:relative;
		transition:.3s;
		.inner-container{
			padding:30px 30px 105px 30px;
			box-sizing:border-box;
			//position:relative;
			transition:.3s;
		}
		.block-title{
			@include font-size( 16px );
			font-weight: $regular-weight;
			text-align:center;
			margin-top:15px;
			color:$darkBlue;
		}
		.button{
			position:absolute;
			bottom:30px;
			left:50%;
			transform:translateX( -50% );
			min-width:35%;
			text-align:center;
		}
		.thumb{
			img{
				max-width:66%;
				margin:0 auto;
			}
		}
		&:hover{
			box-shadow:0 0 0 2px $darkBlue;
			.button{
				background:$darkBlue;
			}
		}
	}
}

.section.expertise-overview .fullwidth .collection.blocks .max-items-1{
	max-width:100%;
}

.section.expertise-overview .collection.blocks .column-row{
	margin-bottom:50px;
	&:last-child{
		margin-bottom:0;
	}
	&:nth-child(even){
		.thumb{
			margin-right:0;
			margin-left:30px;
		}
		.content-wrapper{
			order:-1;
		}
	}
	&:last-child{
		.block.big-expertises{
			padding-bottom:0;
			&:after{
				display:none;
			}
		}
	}
}

.collection.blocks{
	.block.big-expertises{
		display:flex;
		flex-flow: row wrap;
		align-items:center;
		position:relative;
		padding-bottom:50px;
		&:after{
			content:"";
			height:4px;
			background:$darkBlue;
			position:absolute;
			bottom:0;
			left:50%;
			width:270px;
			margin-left:-135px;
		}
		.thumb{
			flex:0 1 25%;
			margin-right:30px;
			img{
				max-width:110px;
				margin:0 auto;
			}
		}
		.content-wrapper{
			flex:1;
			.block-title{
				margin-bottom:15px;
			}
		}
	}
}