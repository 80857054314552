@media only screen and ( max-width: 480px ){

	a[class^="mustache"]{
		position:initial;
		margin:0 auto 10px auto;
	}
	.privacy-policy{
		float:none;
		width:100%;
		text-align:center;
		display:inline-block;
		margin-bottom:10px;
	}

	.footer .copyright .container{
		text-align:center;
	}

}