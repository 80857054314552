@media only screen and ( max-width: 1024px ){

	.collection.blocks .column-row.max-items-4{
		.block{
			flex: 0 1 49%;
			margin:0 2% 30px 0;

			&:nth-of-type(even){
				margin-right:0;
			}

			&:nth-last-of-type(-n+2){
				margin-bottom:0;
			}
		}
	}

	.collection.blocks .column-row{
		&.max-items-6{
			.block{
				flex: 0 1 49%;
				margin-bottom:25px;
				&:nth-child(3n+3){
					margin-right:2%;
				}
				&:nth-child( even ){
					margin-right:0;
				}
				&:nth-child(-n+3){
					margin-bottom:25px;
				}
				&:nth-last-child(-n+2){
					margin-bottom:0;
				}
			}
		}
	}

}

@media only screen and ( max-width: 800px ){

	.collection.blocks .column-row{
		margin-bottom:30px;
		&.max-items-3, &.max-items-1{
			.block, .block:visited{
				flex: 0 1 100%;
				margin:0 0 30px 0;

				&:last-of-type{
					margin-bottom: 0;
				}
			}
		}
	}

}

@media only screen and ( max-width: 768px ){
	.column-row .collection.list{
		margin-right:0;
		width:100%;
		display: block;
		flex: none;
	}
	.column-row .collection{
		margin-right:0;
		flex: 0 0 100% !important;
	}

	.collection.blocks .column-row{
		margin-bottom:30px;
		.block, .block:visited{
			flex: 0 0 100% !important;
			margin:0 0 30px 0 !important;

			&:last-of-type{
				margin-bottom:0 !important;
			}
		}
	}

	.section .fullwidth .collection.list .list-block{
		margin-right:0;
		width:100%;
		display: block;
		flex: none;
		margin-bottom:10px;

		&:last-of-type{
			margin-bottom:0;
		}
	}
}

@media only screen and ( max-width: 600px ){
	
	.section .fullwidth .collection.list li{
		flex: 0 0 100%;
		margin:0 0 10px 0;

		&:last-of-type{
			margin-bottom:0;
		}
	}

	.collection.blocks .column-row.max-items-3, .collection.blocks .column-row.max-items-4{
		padding:0;
	}

	.collection.blocks .column-row.max-items-2{
		margin-bottom:30px;
		.block{
			flex: 0 1 100%;
			margin: 0 0 30px 0;

			&:last-of-type{
				margin-bottom:0;
			}
		}
	}
}
@media only screen and ( max-width: 480px ){
	.collection.blocks .column-row{
		margin-bottom:15px !important;
	}
	.collection.blocks{
		.column-row .block{
			margin:0 0 15px !important;
			&:last-child{
				margin-bottom:0 !important;
			}
		}
	}

}