header{
	background:none;
	border:none;
	transition:.3s;
	.container{
		min-height:65px;
	}
	.logo{
		transform:none;
		top:0;
		max-width:240px;
		transition:.3s;
	}
	.main-nav{
		margin-top:30px;
		transition:.3s;
		ul{
			text-align:right;
			li{
				margin-right:45px;
				a, a:visited{
					color:#FFF;
					border-bottom:2px solid transparent;
					padding:2px;
					text-transform:uppercase;
					&:hover{
						border-bottom-color:$lightBlue;
					}
				}
				&.current-menu-item, &.current-page-parent{
					a, a:visited{
						border-bottom-color:$lightBlue;
					}
				}
				&:last-child{
					margin-right:0;
				}
				&.socials-menu-item{
					padding-left:41px;
					position:relative;
					&:before{
						content:"";
						height:110%;
						top:-10%;
						width:1px;
						background:#FFF;
						display:inline-block;
						position:absolute;
						top:0;
						left:0;
					}
					a, a:visited{
						color:#FFF;
						@include font-size(16px);
						line-height:1;
						padding:2px 4px;
						i{
							color:#FFF;
							line-height:1;
						}
						&:hover{
							background:$lightBlue;
						}
					}
				}
			}
		}
	}
	&.scrolled-state{
		background:$darkBlue;
		.logo{
			max-width:145px;
			top:-30px;
		}
		.main-nav{
			margin-top:45px;
		}
	}

}

.big-header{
	.container{
		min-height:600px;
		display:flex;
		flex-flow:row wrap;
		.left-side{
			flex:1;
			align-self:flex-end;
			.header-btn, .header-btn:visited{
				margin-bottom:30px;
				padding:20px 130px 20px 30px;
				background:$lightBlue;
				color:#FFF;
				box-sizing:border-box;
				text-decoration:none;
				display:inline-block;
				@include font-size( 20px );
				position:relative;
				transition:.3s;

				&:before{
					content:"";
					width:100px;
					height:100%;
					background:#FFF;
					position:absolute;
					top:0;
					right:0;
					display:inline-block;
				}
				&:after{
					content:"\f0da";
					font-family:fontAwesome;
					color:$lightBlue;
					width:100px;
					text-align:center;
					position:absolute;
					top:50%;
					right:0;
					transform:translateY( -50% );
					@include font-size( 40px );
					line-height:1;
					transition:.3s;
					animation: zoomInAndOut linear 8s infinite;
				}
				&:hover{
					background:$darkBlue;
					&:after{
						color:$darkBlue;
					}
				}
			}
		}
		.right-side{
			flex:1;
			align-self:center;
			.payoff-area{
				@include font-size( 58px );
				color:#FFF;
				border-left:8px solid $lightBlue;
				padding:50px 0px 50px 30px;
				display:inline-block;
				margin-left:130px;
				font-family:$special-stack;
			}
		}
	}

}

@keyframes zoomInAndOut {
  0%{
  	@include font-size( 40px );
  }
  2.5%{
  	@include font-size( 50px );
  }
  5%{
  	@include font-size( 40px );
  }
  7.5%{
  	@include font-size( 50px );
  }
  10%{
  	@include font-size( 40px );
  }
  100%{
  	@include font-size( 40px );
  }
}

#main{
	padding-top:0;
}