/**
 * Main CSS File
 *
 * @package ChefBase
 * @since 2018
 */
/**
 * Variable file 
 * place global variables here:
 *
 * @package Boxss
 */
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700|Oswald:700);
/**
 * Mixin file 
 * All our custom mixins
 *
 * @package ChefOehlala
 * @since 2016
 */
/* Boxsizing shortcut: */
/* Transform shortcuts */
/* Styling placeholders: */
/* Retina images: */
/* "Hard" gradients: */
/* REM font sizes: */
/**
* Main CSS file 
* this is where all your imports go.
* 
*/
/**
* General CSS file 
* Styling for all general classes:
* 
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

html, body {
  margin: 0;
  padding: 0; }

body {
  background: #fff;
  font-size: 16px;
  color: #1a2732;
  font-family: "Open Sans", sans-serif; }

/**
 * General Classes:
 */
/* Increase frame-rate when scrolling by not registering pointer-events:*/
.disable-hover, .disable-hover * {
  pointer-events: none !important; }

/* Mustache link: */
a[class^="mustache"] {
  display: block;
  cursor: pointer;
  text-transform: uppercase;
  float: none;
  position: absolute;
  background: url("../images/mustache.png");
  width: 80px;
  height: 15px;
  left: 50%;
  margin-left: -40px;
  top: 5px;
  opacity: 1; }

a[class^="mustache"]:hover {
  opacity: .5; }

a.mustache--black {
  background-position: 0 20px; }

/* Screenreader text support: */
.screen-reader-text {
  clip: rect(1px, 1px, 1px, 1px);
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden; }
  .screen-reader-text:focus {
    background-color: #f1f1f1;
    border-radius: 3px;
    clip: auto !important;
    color: #21759b;
    display: block;
    font-size: rem(14px);
    height: auto;
    left: 5px;
    padding: 15px 23px 14px;
    top: 5px;
    width: auto;
    z-index: 100000;
    /* Above WP toolbar. */ }

.column-wrapper {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: row wrap;
      flex-flow: row wrap; }

.column-wrapper .column, .column-wrapper .collection {
  -ms-flex: 1;
      flex: 1;
  margin-right: 30px;
  box-sizing: border-box; }
  .column-wrapper .column:last-child, .column-wrapper .collection:last-child {
    margin-right: 0; }

img {
  max-width: 100%;
  display: block;
  margin: 0 auto;
  height: auto; }

.column-wrapper.sidebar-left .column, .column-wrapper.sidebar-left .collection {
  -ms-flex: 1;
      flex: 1; }
  .column-wrapper.sidebar-left .column:last-child, .column-wrapper.sidebar-left .collection:last-child {
    -ms-flex: 2;
        flex: 2; }

.column-wrapper.sidebar-right .column, .column-wrapper.sidebar-right .collection {
  -ms-flex: 1;
      flex: 1; }
  .column-wrapper.sidebar-right .column:first-child, .column-wrapper.sidebar-right .collection:first-child {
    -ms-flex: 2;
        flex: 2; }

.column-wrapper.fullwidth {
  -ms-flex-pack: center;
      justify-content: center; }
  .column-wrapper.fullwidth .column {
    -ms-flex: 0 1 770px;
        flex: 0 1 770px; }

.section .section-wrapper {
  padding: 50px 0; }

#main .section .section-wrapper {
  padding: 0 0 50px 0; }

#main .section:first-child .section-wrapper {
  padding-top: 50px; }

.container {
  max-width: 1170px;
  margin: 0 auto; }

body {
  font-family: "Open Sans", sans-serif;
  font-size: 16px; }

.pages {
  text-align: center;
  display: block;
  width: 100%;
  margin-top: 20px; }
  .pages span, .pages a, .pages a:visited {
    width: 44px;
    height: 44px;
    background: #1a2732;
    color: #FFF;
    display: inline-block;
    margin: 10px 10px 0 0;
    text-align: center;
    text-decoration: none;
    padding-top: 15px;
    box-sizing: border-box;
    line-height: 1; }
  .pages span {
    background: #444444;
    color: #1a2732; }

/**
* Textual CSS file 
* Styling for basic typography:
* 
*/
h1, h2, h3, h4, h5, h6 {
  color: #1a2732;
  font-family: "Open Sans", serif;
  line-height: 135%; }

p, a, span, ol, ul, li, strong, em, i, b, big, small, tt, abbr, acronym,
cite, code, dfn, em, kbd, samp, var, br, sub, sup, button, input, label,
select, textarea {
  color: #1a2732;
  font-family: "Open Sans", sans-serif;
  line-height: 135%; }

h1 {
  font-size: 44px; }

h2 {
  font-size: 38px; }

h3 {
  font-size: 28px; }

h4 {
  font-size: 24px; }

h5 {
  font-size: 18px; }

h6 {
  font-size: 14px; }

.column h1, .column h2, .column h3, .column h4, .column h5, .column h6 {
  margin: 30px 0 15px 0; }
  .column h1:first-child, .column h2:first-child, .column h3:first-child, .column h4:first-child, .column h5:first-child, .column h6:first-child {
    margin-top: 0; }

.column .column-title {
  margin: 0 0 15px 0; }

/**
* UI CSS file 
* Everything that enables user-interaction gets bootstrapped here
* 
*/
.button, .btn, a[class^="btn--"] {
  padding: 10px 11px;
  background: #000;
  display: inline-block;
  color: #fff;
  text-decoration: none;
  line-height: 100%; }
  .button:hover, .btn:hover, a[class^="btn--"]:hover {
    background: #444; }

a, a:visited {
  color: #323232;
  text-decoration: underline; }

/**
* Forms CSS file 
* Styling for forms:
* 
*/
.form-column .form {
  padding: 0; }

.form .form-fields .field-wrapper {
  position: relative; }
  .form .form-fields .field-wrapper > label {
    font-size: 16px;
    margin-bottom: 10px;
    display: block;
    width: 100%; }
  .form .form-fields .field-wrapper.radio {
    margin-top: 5px; }
    .form .form-fields .field-wrapper.radio input {
      display: none; }
    .form .form-fields .field-wrapper.radio .subfield-wrapper {
      display: block;
      margin-bottom: 5px; }
      .form .form-fields .field-wrapper.radio .subfield-wrapper label {
        display: inline-block;
        cursor: pointer;
        position: relative;
        padding-left: 30px;
        margin-right: 15px;
        top: -4px; }
        .form .form-fields .field-wrapper.radio .subfield-wrapper label:hover:before {
          background: #d4d4d4; }
    .form .form-fields .field-wrapper.radio .subfield-wrapper label:before {
      content: "";
      display: inline-block;
      width: 20px;
      height: 20px;
      text-align: center;
      transition: .3s;
      margin-right: 10px;
      position: absolute;
      left: 0;
      bottom: 0px;
      background: #e1e1e1;
      border-radius: 50%; }
    .form .form-fields .field-wrapper.radio .subfield-wrapper label:after {
      content: "";
      display: inline-block;
      width: 10px;
      height: 10px;
      transition: .3s;
      position: absolute;
      left: 5px;
      bottom: 5px;
      background: #1a2732;
      border-radius: 50%;
      opacity: 0; }
    .form .form-fields .field-wrapper.radio input[type=radio]:checked + label:after {
      opacity: 1; }
  .form .form-fields .field-wrapper.checkbox input[type=checkbox], .form .form-fields .field-wrapper.checkboxes input[type=checkbox] {
    display: none; }
  .form .form-fields .field-wrapper.checkbox input[type=checkbox]:checked + label:after, .form .form-fields .field-wrapper.checkboxes input[type=checkbox]:checked + label:after {
    opacity: 1; }
  .form .form-fields .field-wrapper.checkbox label, .form .form-fields .field-wrapper.checkboxes label {
    display: inline-block;
    cursor: pointer;
    position: relative;
    padding-left: 30px;
    margin-right: 15px;
    margin-bottom: 10px; }
    .form .form-fields .field-wrapper.checkbox label:hover:before, .form .form-fields .field-wrapper.checkboxes label:hover:before {
      background: #d4d4d4; }
  .form .form-fields .field-wrapper.checkbox label:before, .form .form-fields .field-wrapper.checkboxes label:before {
    content: "";
    display: inline-block;
    transition: .3s;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    position: absolute;
    left: 0;
    bottom: 0;
    background: #e1e1e1; }
  .form .form-fields .field-wrapper.checkbox label:after, .form .form-fields .field-wrapper.checkboxes label:after {
    content: "\2713";
    transition: .3s;
    width: 20px;
    height: 20px;
    position: absolute;
    left: 0;
    bottom: 0px;
    padding-bottom: 8px;
    text-align: center;
    display: inline-block;
    color: #1a2732;
    opacity: 0;
    font-size: 30px;
    line-height: 100%; }
  .form .form-fields .field-wrapper.checkboxes > label {
    display: block;
    margin: 0 0 10px 0;
    padding: 0;
    cursor: auto;
    clip: none;
    position: relative !important;
    height: auto;
    width: auto;
    overflow: visible; }
    .form .form-fields .field-wrapper.checkboxes > label:before {
      display: none; }

.form .form-fields .field-wrapper .subfield-wrapper label {
  font-size: 14px; }

.form .form-fields .field-wrapper .subfield-wrapper {
  display: block;
  width: 100%; }

.select {
  position: relative;
  z-index: 2;
  cursor: pointer;
  position: relative; }
  .select select {
    cursor: pointer;
    -webkit-appearance: none;
    -webkit-border-radius: 0px;
    background: #FFF;
    width: 100%; }
    .select select:focus {
      outline: none; }
  .select:after {
    content: "";
    height: 40px;
    width: 44px;
    pointer-events: none;
    display: block;
    position: absolute;
    background: #1a2732;
    bottom: 0;
    right: 0;
    transition: .3s; }
  .select:before {
    content: "\f078";
    position: absolute;
    right: 0;
    width: 44px;
    bottom: 14px;
    color: #fff;
    text-align: center;
    font-family: fontAwesome;
    font-size: 12px;
    pointer-events: none;
    z-index: 2; }
  .select:hover:after {
    background: #e1e1e1; }

.ui-datepicker {
  width: 250px;
  background: #FFF;
  z-index: 10;
  border: 1px solid #e1e1e1;
  padding: 5px;
  box-shadow: 0 0 5px #d4d4d4;
  display: none; }
  .ui-datepicker a, .ui-datepicker a:visited {
    text-decoration: none; }
  .ui-datepicker .ui-datepicker-header {
    margin-bottom: 5px; }
  .ui-datepicker .ui-datepicker-next {
    float: right; }
  .ui-datepicker .ui-datepicker-prev, .ui-datepicker .ui-datepicker-next {
    margin-bottom: 10px;
    cursor: pointer; }
    .ui-datepicker .ui-datepicker-prev span, .ui-datepicker .ui-datepicker-next span {
      transition: .3s; }
  .ui-datepicker .ui-state-default {
    color: #1a2732;
    transition: .3s; }

.form .form-fields .field-row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: row wrap;
      flex-flow: row wrap;
  -ms-flex-align: start;
      align-items: flex-start; }
  .form .form-fields .field-row .field-wrapper {
    margin-bottom: 15px;
    -ms-flex: 1;
        flex: 1; }
    .form .form-fields .field-row .field-wrapper textarea, .form .form-fields .field-row .field-wrapper input, .form .form-fields .field-row .field-wrapper select {
      width: 100%;
      padding: 8px 12px;
      box-sizing: border-box;
      border: 1px solid #e1e1e1;
      font-size: 16px;
      background-size: 16px auto;
      background-repeat: no-repeat;
      background-position: 97.5% center; }
      .form .form-fields .field-row .field-wrapper textarea.placeholder, .form .form-fields .field-row .field-wrapper input.placeholder, .form .form-fields .field-row .field-wrapper select.placeholder {
        font-size: 16px; }
      .form .form-fields .field-row .field-wrapper textarea:-moz-placeholder, .form .form-fields .field-row .field-wrapper input:-moz-placeholder, .form .form-fields .field-row .field-wrapper select:-moz-placeholder {
        font-size: 16px; }
      .form .form-fields .field-row .field-wrapper textarea::-moz-placeholder, .form .form-fields .field-row .field-wrapper input::-moz-placeholder, .form .form-fields .field-row .field-wrapper select::-moz-placeholder {
        font-size: 16px; }
      .form .form-fields .field-row .field-wrapper textarea::-webkit-input-placeholder, .form .form-fields .field-row .field-wrapper input::-webkit-input-placeholder, .form .form-fields .field-row .field-wrapper select::-webkit-input-placeholder {
        font-size: 16px; }
      .form .form-fields .field-row .field-wrapper textarea.validated-false:focus, .form .form-fields .field-row .field-wrapper input.validated-false:focus, .form .form-fields .field-row .field-wrapper select.validated-false:focus {
        border: 1px solid #ae2d30; }
      .form .form-fields .field-row .field-wrapper textarea:focus, .form .form-fields .field-row .field-wrapper input:focus, .form .form-fields .field-row .field-wrapper select:focus {
        outline: none; }
        .form .form-fields .field-row .field-wrapper textarea:focus + .validation-error, .form .form-fields .field-row .field-wrapper input:focus + .validation-error, .form .form-fields .field-row .field-wrapper select:focus + .validation-error {
          opacity: 1; }
    .form .form-fields .field-row .field-wrapper textarea {
      height: 100px;
      background-position: 97.5% 48%; }
    .form .form-fields .field-row .field-wrapper .validation-error {
      background: #ae2d30;
      padding: 4px 8px;
      color: #FFF;
      position: absolute;
      top: -32px;
      right: 0;
      opacity: 0;
      pointer-events: none;
      transition: .3s; }
      .form .form-fields .field-row .field-wrapper .validation-error:after {
        content: "";
        position: absolute;
        bottom: -10px;
        width: 0;
        left: 50%;
        transform: translateX(-50%);
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 10px solid #ae2d30; }
  .form .form-fields .field-row .field-wrapper {
    margin-right: 15px; }
  .form .form-fields .field-row .field-wrapper:last-of-type {
    margin-right: 0; }

.form-fields .field-row .field-wrapper input.validated-true, .form-fields .field-row .field-wrapper textarea.validated-true, .form-fields .field-row .field-wrapper select.validated-true {
  background-image: url(../images/ok.png); }

.form-fields .field-row .field-wrapper input.validated-false, .form-fields .field-row .field-wrapper textarea.validated-false, .form-fields .field-row .field-wrapper select.validated-false {
  background-image: url(../images/fail.png); }

.form-footer .submit-form {
  cursor: pointer; }

.form .loader {
  position: absolute;
  display: none;
  pointer-events: none;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 140px;
  z-index: 2; }

.form {
  position: relative; }

.loader {
  width: 70px;
  text-align: center; }

.form.active .loader {
  display: block;
  pointer-events: auto; }

.loader > div {
  width: 18px;
  height: 18px;
  border-radius: 100%;
  display: inline-block;
  animation: bouncedelay 1.4s infinite ease-in-out;
  /* Prevent first frame from flickering when animation starts */
  animation-fill-mode: both; }

.loader .bounce1 {
  animation-delay: -0.32s; }

.loader .bounce2 {
  animation-delay: -0.16s; }

@keyframes bouncedelay {
  0%, 80%, 100% {
    transform: scale(0);
    -webkit-transform: scale(0); }
  40% {
    transform: scale(1);
    -webkit-transform: scale(1); } }

.form .loader {
  position: absolute;
  display: none;
  pointer-events: none;
  top: 50%;
  left: 50%;
  right: 0;
  z-index: 2;
  transform: translateY(-50%) translateX(-50%); }

.form .loader > div {
  width: 24px;
  height: 24px; }

.form {
  position: relative; }

.form.msg:before {
  content: "";
  position: absolute;
  z-index: 100;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  width: 100%;
  z-index: 100;
  background: rgba(255, 255, 255, 0.7); }

.form .message {
  position: absolute;
  padding: 30px;
  font-size: 20px;
  width: 270px;
  left: 50%;
  margin-left: -135px;
  top: 50%;
  z-index: 105;
  transform: translateY(-50%); }

/**
 * Header CSS file 
 * Styling for the header:
 */
header {
  width: 100%;
  background: #fff;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  border-bottom: 2px solid #444444; }
  header .container {
    min-height: 78px;
    position: relative; }
  header .logo {
    padding: 0;
    text-decoration: none;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
    max-width: 160px;
    display: block;
    width: 100%; }
    header .logo img {
      display: block;
      width: 100%;
      height: auto; }

#main {
  padding-top: 98px; }

header .container {
  min-height: auto; }

header .main-nav {
  text-align: center;
  display: block;
  width: 100%; }
  header .main-nav ul {
    padding-left: 0;
    list-style: none; }
    header .main-nav ul li {
      display: inline-block; }
      header .main-nav ul li a, header .main-nav ul li a:visited {
        padding: 15px 25px;
        display: inline-block;
        text-decoration: none;
        transition: .3s;
        line-height: 1; }

.big-header {
  background-size: cover;
  background-position: center center; }
  .big-header .container {
    min-height: 400px; }

.mobile-nav {
  top: 150px;
  height: calc(100vh - 150px);
  overflow-y: scroll;
  width: 100%;
  position: fixed;
  right: 0%;
  left: 0;
  padding: 0;
  opacity: 0;
  transition: .3s;
  z-index: 900;
  text-align: center;
  pointer-events: none;
  display: none; }
  .mobile-nav ul {
    list-style: none;
    display: block;
    padding-left: 0;
    width: 100%; }
    .mobile-nav ul li {
      display: block;
      width: 100%; }
      .mobile-nav ul li a, .mobile-nav ul li a:visited {
        width: 100%;
        display: block;
        padding: 10px 20px;
        text-decoration: none;
        color: #FFF; }
      .mobile-nav ul li.current-menu-item a, .mobile-nav ul li.current-menu-item a:visited, .mobile-nav ul li.current-page-parent a, .mobile-nav ul li.current-page-parent a:visited {
        background: #FFF;
        color: #1a2732; }
  .mobile-nav.fold-out {
    opacity: 1;
    pointer-events: auto; }

.menu-switch {
  top: 50%;
  height: 44px;
  width: 44px;
  text-align: center;
  margin-top: -22px;
  right: 4%;
  border-radius: 0;
  padding-top: 12px;
  z-index: 100;
  background: #1a2732;
  position: absolute;
  display: none; }
  .menu-switch .mobile-button-text {
    position: absolute;
    left: -42px;
    color: #1a2732;
    font-weight: 400;
    text-transform: none;
    font-size: 14px; }
  .menu-switch i {
    color: #FFF;
    font-size: 18px;
    position: relative;
    z-index: 2; }

.background-wrapper {
  position: fixed;
  z-index: 800;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: none;
  pointer-events: none;
  opacity: 0;
  background: #1a2732;
  transition: .3s;
  display: none; }
  .background-wrapper.active-bg {
    opacity: 1;
    pointer-events: auto; }

/**
* Footer CSS file 
* Styling for the footer:
* 
*/
.footer .copyright {
  padding: 20px 0;
  background: #1a2732;
  color: #FFF; }
  .footer .copyright a, .footer .copyright a:visited, .footer .copyright span {
    color: #FFF; }
  .footer .copyright .container {
    position: relative; }
    .footer .copyright .container .chef-logo {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%); }
      .footer .copyright .container .chef-logo img {
        display: block;
        max-width: 100%;
        margin: 0 auto; }

.collection.list .block-row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: row wrap;
      flex-flow: row wrap;
  width: 100% !important; }

.collection.list .list-block, .collection.list .list-block:visited {
  -ms-flex: 0 0 100%;
      flex: 0 0 100%;
  transition: .3s;
  cursor: pointer;
  text-decoration: none; }
  .collection.list .list-block:last-of-type, .collection.list .list-block:visited:last-of-type {
    margin: 0; }

.section .fullwidth .collection.list .list-block, .section .fullwidth .collection.list .list-block:visited {
  -ms-flex: 0 0 49%;
      flex: 0 0 49%;
  margin-right: 2%; }
  .section .fullwidth .collection.list .list-block:nth-of-type(even), .section .fullwidth .collection.list .list-block:visited:nth-of-type(even) {
    margin-right: 0; }
  .section .fullwidth .collection.list .list-block:nth-last-child(-n+2), .section .fullwidth .collection.list .list-block:visited:nth-last-child(-n+2) {
    margin-bottom: 0; }

.collection {
  -ms-flex: 0 0 100%;
      flex: 0 0 100%; }

.collection.blocks .column-row {
  -ms-flex-flow: row wrap;
      flex-flow: row wrap;
  margin-bottom: 30px;
  display: -ms-flexbox;
  display: flex; }
  .collection.blocks .column-row:last-of-type {
    margin-bottom: 0 !important; }
  .collection.blocks .column-row .block, .collection.blocks .column-row .block:visited {
    -ms-flex: 1;
        flex: 1;
    box-sizing: border-box; }
  .collection.blocks .column-row.max-items-6 .block {
    -ms-flex: 0 1 32%;
        flex: 0 1 32%; }
    .collection.blocks .column-row.max-items-6 .block:nth-child(3n+3) {
      margin-right: 0; }
    .collection.blocks .column-row.max-items-6 .block:nth-child(-n+3) {
      margin-bottom: 25px; }
  .collection.blocks .column-row.max-items-4 .block {
    -ms-flex: 0 1 23.5%;
        flex: 0 1 23.5%; }
  .collection.blocks .column-row.max-items-3 .block {
    -ms-flex: 0 1 32%;
        flex: 0 1 32%; }
  .collection.blocks .column-row.max-items-2 .block {
    -ms-flex: 0 1 49%;
        flex: 0 1 49%; }
  .collection.blocks .column-row.max-items-1 .block {
    -ms-flex: 0 1 100%;
        flex: 0 1 100%; }

.collection.blocks .block, .collection.blocks .block:visited {
  -ms-flex: 1;
      flex: 1;
  margin-right: 2%;
  text-decoration: none; }
  .collection.blocks .block:last-of-type, .collection.blocks .block:visited:last-of-type {
    margin-right: 0; }
  .collection.blocks .block:last-of-type, .collection.blocks .block:visited:last-of-type {
    margin-right: 0; }

.section .fullwidth .collection.blocks .max-items-1 {
  max-width: 770px;
  margin-left: auto;
  margin-right: auto; }

.collection {
  position: relative;
  transition: .3s; }
  .collection.loading {
    opacity: 0.6; }
    .collection.loading .loader {
      opacity: 1; }
  .collection .loader {
    opacity: 0;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateY(-50%) translateX(-50%);
    transition: .3s;
    z-index: 4; }
    .collection .loader > div {
      background: #1a2732;
      border-radius: 0; }

.collection.blocks.masonry {
  -ms-flex: none;
      flex: none;
  display: block;
  width: 100%; }
  .collection.blocks.masonry .block {
    -ms-flex: none;
        flex: none;
    width: 32%;
    margin-bottom: 15px;
    display: block;
    margin-right: 0 !important; }

@media only screen and (max-width: 1024px) {
  header .main-nav, header .top-nav {
    display: none; }
  .mobile-nav {
    display: block; }
  .background-wrapper {
    display: block; }
  .menu-switch {
    display: inline-block; } }

@media only screen and (max-width: 1220px) {
  .container {
    padding: 0 4%; } }

@media only screen and (max-width: 1024px) {
  .collection.blocks .column-row.max-items-4 .block {
    -ms-flex: 0 1 49%;
        flex: 0 1 49%;
    margin: 0 2% 30px 0; }
    .collection.blocks .column-row.max-items-4 .block:nth-of-type(even) {
      margin-right: 0; }
    .collection.blocks .column-row.max-items-4 .block:nth-last-of-type(-n+2) {
      margin-bottom: 0; }
  .collection.blocks .column-row.max-items-6 .block {
    -ms-flex: 0 1 49%;
        flex: 0 1 49%;
    margin-bottom: 25px; }
    .collection.blocks .column-row.max-items-6 .block:nth-child(3n+3) {
      margin-right: 2%; }
    .collection.blocks .column-row.max-items-6 .block:nth-child(even) {
      margin-right: 0; }
    .collection.blocks .column-row.max-items-6 .block:nth-child(-n+3) {
      margin-bottom: 25px; }
    .collection.blocks .column-row.max-items-6 .block:nth-last-child(-n+2) {
      margin-bottom: 0; } }

@media only screen and (max-width: 800px) {
  .collection.blocks .column-row {
    margin-bottom: 30px; }
    .collection.blocks .column-row.max-items-3 .block, .collection.blocks .column-row.max-items-3 .block:visited, .collection.blocks .column-row.max-items-1 .block, .collection.blocks .column-row.max-items-1 .block:visited {
      -ms-flex: 0 1 100%;
          flex: 0 1 100%;
      margin: 0 0 30px 0; }
      .collection.blocks .column-row.max-items-3 .block:last-of-type, .collection.blocks .column-row.max-items-3 .block:visited:last-of-type, .collection.blocks .column-row.max-items-1 .block:last-of-type, .collection.blocks .column-row.max-items-1 .block:visited:last-of-type {
        margin-bottom: 0; } }

@media only screen and (max-width: 768px) {
  .column-row .collection.list {
    margin-right: 0;
    width: 100%;
    display: block;
    -ms-flex: none;
        flex: none; }
  .column-row .collection {
    margin-right: 0;
    -ms-flex: 0 0 100% !important;
        flex: 0 0 100% !important; }
  .collection.blocks .column-row {
    margin-bottom: 30px; }
    .collection.blocks .column-row .block, .collection.blocks .column-row .block:visited {
      -ms-flex: 0 0 100% !important;
          flex: 0 0 100% !important;
      margin: 0 0 30px 0 !important; }
      .collection.blocks .column-row .block:last-of-type, .collection.blocks .column-row .block:visited:last-of-type {
        margin-bottom: 0 !important; }
  .section .fullwidth .collection.list .list-block {
    margin-right: 0;
    width: 100%;
    display: block;
    -ms-flex: none;
        flex: none;
    margin-bottom: 10px; }
    .section .fullwidth .collection.list .list-block:last-of-type {
      margin-bottom: 0; } }

@media only screen and (max-width: 600px) {
  .section .fullwidth .collection.list li {
    -ms-flex: 0 0 100%;
        flex: 0 0 100%;
    margin: 0 0 10px 0; }
    .section .fullwidth .collection.list li:last-of-type {
      margin-bottom: 0; }
  .collection.blocks .column-row.max-items-3, .collection.blocks .column-row.max-items-4 {
    padding: 0; }
  .collection.blocks .column-row.max-items-2 {
    margin-bottom: 30px; }
    .collection.blocks .column-row.max-items-2 .block {
      -ms-flex: 0 1 100%;
          flex: 0 1 100%;
      margin: 0 0 30px 0; }
      .collection.blocks .column-row.max-items-2 .block:last-of-type {
        margin-bottom: 0; } }

@media only screen and (max-width: 480px) {
  .collection.blocks .column-row {
    margin-bottom: 15px !important; }
  .collection.blocks .column-row .block {
    margin: 0 0 15px !important; }
    .collection.blocks .column-row .block:last-child {
      margin-bottom: 0 !important; } }

@media only screen and (max-width: 1024px) {
  .column.slider-column .main-gallery.images-5 .gallery-cell {
    width: 23.5%; } }

@media only screen and (max-width: 1024px) {
  .column-wrapper.four-columns .column {
    -ms-flex: 0 0 49%;
        flex: 0 0 49%;
    margin-right: 2%; }
    .column-wrapper.four-columns .column:nth-child(1), .column-wrapper.four-columns .column:nth-child(2) {
      margin-bottom: 20px; }
    .column-wrapper.four-columns .column:nth-child(even) {
      margin-right: 0; }
  .collection.gallery .gallery-row {
    -ms-flex-flow: row wrap;
        flex-flow: row wrap;
    margin-bottom: 0; }
    .collection.gallery .gallery-row .gallery-item, .collection.gallery .gallery-row .gallery-item:visited {
      -ms-flex: 49%;
          flex: 49%;
      max-width: 49%;
      margin: 0 2% 20px 0; }
      .collection.gallery .gallery-row .gallery-item:nth-of-type(even), .collection.gallery .gallery-row .gallery-item:visited:nth-of-type(even) {
        margin-right: 0; }
  .column.slider-column .main-gallery.images-4 .gallery-cell, .column.slider-column .main-gallery.images-5 .gallery-cell {
    width: 32.5%; } }

@media only screen and (max-width: 980px) {
  .section .column-wrapper.sidebar-left .column, .section .column-wrapper.sidebar-right .column {
    -ms-flex: 1;
        flex: 1; } }

@media only screen and (max-width: 850px) {
  .column-wrapper.three-columns .column {
    -ms-flex: 0 0 100%;
        flex: 0 0 100%;
    margin: 0 0 30px 0; }
    .column-wrapper.three-columns .column:last-child {
      margin-bottom: 0; } }

@media only screen and (max-width: 800px) {
  .column.slider-column .main-gallery.images-3 .gallery-cell, .column.slider-column .main-gallery.images-4 .gallery-cell, .column.slider-column .main-gallery.images-5 .gallery-cell {
    width: 49%; } }

@media only screen and (max-width: 768px) {
  .column-row > * {
    margin-bottom: 30px; }
    .column-row > *:last-child {
      margin-bottom: 0 !important; }
  .section .column-wrapper.half-half .column, .section .column-wrapper.four-columns .column, .section .column-wrapper.sidebar-left .column, .section .column-wrapper.sidebar-right .column {
    -ms-flex: 0 0 100%;
        flex: 0 0 100%;
    margin-right: 0; }
    .section .column-wrapper.half-half .column:nth-child(even), .section .column-wrapper.four-columns .column:nth-child(even), .section .column-wrapper.sidebar-left .column:nth-child(even), .section .column-wrapper.sidebar-right .column:nth-child(even) {
      margin-right: 0; }
  .section .filter-column {
    -ms-flex-order: -1;
        order: -1;
    margin-bottom: 30px !important; }
  .column-wrapper .column {
    margin-bottom: 30px; }
    .column-wrapper .column:last-of-type {
      margin-bottom: 0; }
  .column-wrapper.four-columns .column {
    margin-bottom: 30px; }
    .column-wrapper.four-columns .column:nth-child(1), .column-wrapper.four-columns .column:nth-child(2) {
      margin-bottom: 30px; }
    .column-wrapper.four-columns .column:last-of-type {
      margin-bottom: 0; }
  .slider-column .main-gallery .gallery-cell {
    width: 32%; } }

@media only screen and (max-width: 767px) {
  .form .form-fields .field-row .field-wrapper {
    -ms-flex: 0 0 100%;
        flex: 0 0 100%;
    margin: 0 0 15px 0; } }

@media only screen and (max-width: 600px) {
  .column.slider-column .main-gallery .gallery-cell {
    width: 100%; }
  .column.slider-column .main-gallery.images-2 .gallery-cell, .column.slider-column .main-gallery.images-3 .gallery-cell, .column.slider-column .main-gallery.images-4 .gallery-cell, .column.slider-column .main-gallery.images-5 .gallery-cell {
    width: 100%; }
  .slider-column .main-gallery .gallery-cell {
    width: 49%; } }

@media only screen and (max-width: 480px) {
  .column.slider-column .main-gallery {
    padding: 0; }
  .column.slider-column .flickity-prev-next-button {
    display: none; }
  .column.slider-column .main-gallery.nav-arrows-true {
    padding: 0; }
  .collection.gallery .gallery-row {
    -ms-flex: none;
        flex: none;
    display: block; }
    .collection.gallery .gallery-row .gallery-item, .collection.gallery .gallery-row .gallery-item:visited {
      -ms-flex: none;
          flex: none;
      display: block;
      max-width: none;
      width: 100%;
      margin: 0 0 15px 0; }
  .section .five-columns {
    -ms-flex: none;
        flex: none;
    display: block; }
    .section .five-columns .column {
      -ms-flex: none;
          flex: none;
      display: block;
      width: 100%;
      max-width: 100%;
      margin: 0 0 15px 0; } }

@media only screen and (max-width: 400px) {
  .slider-column .main-gallery .gallery-cell {
    width: 100%; }
  .share-buttons .share-text {
    display: block;
    margin: 0 0 15px 0; } }

.gfield_label {
  clip: rect(1px, 1px, 1px, 1px);
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden; }

.column.gf-column ul li {
  margin-bottom: 15px;
  position: relative; }
  .column.gf-column ul li:last-child {
    margin-bottom: 0; }

.column.gf-column .ginput_container {
  position: relative; }

.column.gf-column input[type=text], .column.gf-column textarea {
  padding: 7px 14px;
  display: inline-block;
  width: 100%;
  box-sizing: border-box;
  font-size: 16px;
  font-size: 1rem;
  border: 2px solid #FFF; }
  .column.gf-column input[type=text]:focus, .column.gf-column textarea:focus {
    outline: none;
    border-color: #21a1b7; }

.column.gf-column textarea {
  overflow-y: auto; }

.column.gf-column .gform_footer {
  margin-top: 30px; }

.column.gf-column form > .validation_error {
  color: #ae2d30;
  border: 2px solid #ae2d30;
  border-left: none;
  border-right: none;
  margin-bottom: 15px;
  padding: 15px 20px; }

.column.gf-column .gfield_error {
  position: relative;
  z-index: 2; }

.column.gf-column .gfield_error .validation_message {
  color: #FFF;
  border-color: #ae2d30;
  position: absolute;
  top: 16px;
  left: 0;
  bottom: auto;
  background: #ae2d30;
  padding: 2px 4px;
  display: inline-block;
  width: auto;
  transform: translateY(100%);
  z-index: 10;
  font-size: 10px;
  font-size: 0.625rem; }
  .column.gf-column .gfield_error .validation_message:before {
    content: "";
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid #ae2d30;
    position: absolute;
    left: 50%;
    top: -6px;
    margin-left: -6px;
    z-index: 11; }

.column.gf-column .gform_wrapper.gform_validation_error .gform_body ul li.gfield.gfield_error:not(.gf_left_half):not(.gf_right_half) {
  max-width: 100%; }

.column.gf-column .gform_wrapper ul.gform_fields li.gfield {
  padding-right: 0; }

.column.gf-column .gfield_checkbox input[type=checkbox] {
  display: none; }

.column.gf-column .gfield_checkbox input[type=checkbox]:checked + label:after {
  opacity: 1; }

.column.gf-column .gfield_checkbox label {
  display: inline-block;
  cursor: pointer;
  position: relative;
  padding-left: 30px;
  margin-right: 15px;
  margin-bottom: 10px;
  color: #FFF; }
  .column.gf-column .gfield_checkbox label:hover:before {
    background: #21a1b7; }
  .column.gf-column .gfield_checkbox label a, .column.gf-column .gfield_checkbox label a:visited {
    color: #FFF;
    transition: .3s; }
    .column.gf-column .gfield_checkbox label a:hover, .column.gf-column .gfield_checkbox label a:visited:hover {
      color: #21a1b7; }

.column.gf-column .gfield_checkbox label:before {
  content: "";
  display: inline-block;
  transition: .3s;
  width: 20px;
  height: 20px;
  margin-right: 10px;
  position: absolute;
  left: 0;
  bottom: 0;
  background: #FFF; }

.column.gf-column .gfield_checkbox label:after {
  content: "\2713";
  transition: .3s;
  width: 20px;
  height: 20px;
  position: absolute;
  left: 0;
  bottom: 0px;
  padding-bottom: 8px;
  text-align: center;
  display: inline-block;
  color: #000;
  opacity: 0;
  font-size: 30px;
  line-height: 100%; }

body {
  font-size: 16px;
  font-size: 1rem; }

h1, h2, h3, h4, h5, h6 {
  font-weight: 700;
  color: #21a1b7; }

h1 {
  font-size: 30px;
  font-size: 1.875rem; }

h2 {
  font-size: 24px;
  font-size: 1.5rem; }

h3 {
  font-size: 20px;
  font-size: 1.25rem; }

h4 {
  font-size: 18px;
  font-size: 1.125rem; }

h5 {
  font-size: 17px;
  font-size: 1.0625rem; }

h6 {
  font-size: 16px;
  font-size: 1rem; }

em {
  font-style: italic; }

.button, .btn, a[class^="btn--"], .button:visited, .btn:visited, a[class^="btn--"]:visited {
  background: #21a1b7;
  color: #FFF;
  padding: 15px 30px;
  display: inline-block;
  border-radius: 4px;
  font-weight: 400;
  position: relative;
  transition: .3s;
  color: #FFF;
  border: none;
  cursor: pointer;
  font-size: 16px;
  font-size: 1rem;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  outline: none; }
  .button:hover, .btn:hover, a[class^="btn--"]:hover, .button:focus, .btn:focus, a[class^="btn--"]:focus, .button:visited:hover, .btn:visited:hover, a[class^="btn--"]:visited:hover, .button:visited:focus, .btn:visited:focus, a[class^="btn--"]:visited:focus {
    background: #1a2732;
    color: #FFF; }

.button-wrapper {
  text-align: center;
  margin-top: 30px; }

.intro p {
  font-style: italic;
  font-weight: 300;
  color: #21a1b7;
  font-size: 20px;
  font-size: 1.25rem; }

.column h1, .column h2, .column h3, .column h4, .column h5, .column h6, .column p, .column .intro {
  margin-bottom: 15px; }
  .column h1:last-child, .column h2:last-child, .column h3:last-child, .column h4:last-child, .column h5:last-child, .column h6:last-child, .column p:last-child, .column .intro:last-child {
    margin-bottom: 0; }

.section .section-title {
  margin-bottom: 30px; }

.section .anchor {
  position: relative;
  top: -100px;
  display: inline-block;
  height: 0;
  width: 0; }

header {
  background: none;
  border: none;
  transition: .3s; }
  header .container {
    min-height: 65px; }
  header .logo {
    transform: none;
    top: 0;
    max-width: 240px;
    transition: .3s; }
  header .main-nav {
    margin-top: 30px;
    transition: .3s; }
    header .main-nav ul {
      text-align: right; }
      header .main-nav ul li {
        margin-right: 45px; }
        header .main-nav ul li a, header .main-nav ul li a:visited {
          color: #FFF;
          border-bottom: 2px solid transparent;
          padding: 2px;
          text-transform: uppercase; }
          header .main-nav ul li a:hover, header .main-nav ul li a:visited:hover {
            border-bottom-color: #21a1b7; }
        header .main-nav ul li.current-menu-item a, header .main-nav ul li.current-menu-item a:visited, header .main-nav ul li.current-page-parent a, header .main-nav ul li.current-page-parent a:visited {
          border-bottom-color: #21a1b7; }
        header .main-nav ul li:last-child {
          margin-right: 0; }
        header .main-nav ul li.socials-menu-item {
          padding-left: 41px;
          position: relative; }
          header .main-nav ul li.socials-menu-item:before {
            content: "";
            height: 110%;
            top: -10%;
            width: 1px;
            background: #FFF;
            display: inline-block;
            position: absolute;
            top: 0;
            left: 0; }
          header .main-nav ul li.socials-menu-item a, header .main-nav ul li.socials-menu-item a:visited {
            color: #FFF;
            font-size: 16px;
            font-size: 1rem;
            line-height: 1;
            padding: 2px 4px; }
            header .main-nav ul li.socials-menu-item a i, header .main-nav ul li.socials-menu-item a:visited i {
              color: #FFF;
              line-height: 1; }
            header .main-nav ul li.socials-menu-item a:hover, header .main-nav ul li.socials-menu-item a:visited:hover {
              background: #21a1b7; }
  header.scrolled-state {
    background: #1a2732; }
    header.scrolled-state .logo {
      max-width: 145px;
      top: -30px; }
    header.scrolled-state .main-nav {
      margin-top: 45px; }

.big-header .container {
  min-height: 600px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: row wrap;
      flex-flow: row wrap; }
  .big-header .container .left-side {
    -ms-flex: 1;
        flex: 1;
    -ms-flex-item-align: end;
        align-self: flex-end; }
    .big-header .container .left-side .header-btn, .big-header .container .left-side .header-btn:visited {
      margin-bottom: 30px;
      padding: 20px 130px 20px 30px;
      background: #21a1b7;
      color: #FFF;
      box-sizing: border-box;
      text-decoration: none;
      display: inline-block;
      font-size: 20px;
      font-size: 1.25rem;
      position: relative;
      transition: .3s; }
      .big-header .container .left-side .header-btn:before, .big-header .container .left-side .header-btn:visited:before {
        content: "";
        width: 100px;
        height: 100%;
        background: #FFF;
        position: absolute;
        top: 0;
        right: 0;
        display: inline-block; }
      .big-header .container .left-side .header-btn:after, .big-header .container .left-side .header-btn:visited:after {
        content: "\f0da";
        font-family: fontAwesome;
        color: #21a1b7;
        width: 100px;
        text-align: center;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        font-size: 40px;
        font-size: 2.5rem;
        line-height: 1;
        transition: .3s;
        animation: zoomInAndOut linear 8s infinite; }
      .big-header .container .left-side .header-btn:hover, .big-header .container .left-side .header-btn:visited:hover {
        background: #1a2732; }
        .big-header .container .left-side .header-btn:hover:after, .big-header .container .left-side .header-btn:visited:hover:after {
          color: #1a2732; }
  .big-header .container .right-side {
    -ms-flex: 1;
        flex: 1;
    -ms-flex-item-align: center;
        -ms-grid-row-align: center;
        align-self: center; }
    .big-header .container .right-side .payoff-area {
      font-size: 58px;
      font-size: 3.625rem;
      color: #FFF;
      border-left: 8px solid #21a1b7;
      padding: 50px 0px 50px 30px;
      display: inline-block;
      margin-left: 130px;
      font-family: "Oswald", serif; }

@keyframes zoomInAndOut {
  0% {
    font-size: 40px;
    font-size: 2.5rem; }
  2.5% {
    font-size: 50px;
    font-size: 3.125rem; }
  5% {
    font-size: 40px;
    font-size: 2.5rem; }
  7.5% {
    font-size: 50px;
    font-size: 3.125rem; }
  10% {
    font-size: 40px;
    font-size: 2.5rem; }
  100% {
    font-size: 40px;
    font-size: 2.5rem; } }

#main {
  padding-top: 0; }

.footer-section {
  background: #1a2732; }
  .footer-section h1, .footer-section h2, .footer-section h3, .footer-section h4, .footer-section h5, .footer-section h6, .footer-section p {
    color: #FFF; }
  .footer-section .column.gf-column input[type=text], .footer-section .column.gf-column textarea {
    background: none;
    border-color: #FFF;
    color: #FFF; }
    .footer-section .column.gf-column input[type=text].placeholder, .footer-section .column.gf-column textarea.placeholder {
      color: #FFF; }
    .footer-section .column.gf-column input[type=text]:-moz-placeholder, .footer-section .column.gf-column textarea:-moz-placeholder {
      color: #FFF; }
    .footer-section .column.gf-column input[type=text]::-moz-placeholder, .footer-section .column.gf-column textarea::-moz-placeholder {
      color: #FFF; }
    .footer-section .column.gf-column input[type=text]::-webkit-input-placeholder, .footer-section .column.gf-column textarea::-webkit-input-placeholder {
      color: #FFF; }
  .footer-section .column.content a, .footer-section .column.content a:visited {
    color: #FFF; }
    .footer-section .column.content a:hover, .footer-section .column.content a:visited:hover {
      color: #21a1b7; }
  .footer-section .column .button:hover, .footer-section .column .btn:hover, .footer-section .column a[class^="btn--"]:hover, .footer-section .column .button:visited, .footer-section .column .btn:visited, .footer-section .column a[class^="btn--"]:visited {
    background: #ffefc3;
    color: #1a2732; }

.footer .copyright {
  background: #e3e3e3;
  color: #1a2732; }
  .footer .copyright a, .footer .copyright a:visited, .footer .copyright span {
    color: #1a2732; }
  .footer .copyright .privacy-policy, .footer .copyright .privacy-policy:visited {
    float: right;
    transition: .3s; }
    .footer .copyright .privacy-policy:hover, .footer .copyright .privacy-policy:visited:hover {
      color: #21a1b7; }

a[class^="mustache"] {
  background-position: bottom center;
  transition: .3s; }

.column.featured-column .repeater-item {
  background: #ffefc3;
  padding: 30px;
  margin-bottom: 15px;
  border-radius: 4px; }
  .column.featured-column .repeater-item .featured-title {
    color: #21a1b7;
    text-transform: uppercase;
    font-weight: 700;
    margin-bottom: 5px; }
  .column.featured-column .repeater-item .featured-text {
    color: #1a2732; }
  .column.featured-column .repeater-item:last-child {
    margin-bottom: 0; }
  .column.featured-column .repeater-item:nth-child(even) {
    background: #1a2732; }
    .column.featured-column .repeater-item:nth-child(even) .featured-title {
      color: #21a1b7; }
    .column.featured-column .repeater-item:nth-child(even) .featured-text {
      color: #ffefc3; }

.collection.blocks .block.block-expertises {
  box-shadow: 0 0 0 2px #e3e3e3;
  box-sizing: border-box;
  position: relative;
  transition: .3s; }
  .collection.blocks .block.block-expertises .inner-container {
    padding: 30px 30px 105px 30px;
    box-sizing: border-box;
    transition: .3s; }
  .collection.blocks .block.block-expertises .block-title {
    font-size: 16px;
    font-size: 1rem;
    font-weight: 400;
    text-align: center;
    margin-top: 15px;
    color: #1a2732; }
  .collection.blocks .block.block-expertises .button, .collection.blocks .block.block-expertises .btn, .collection.blocks .block.block-expertises a[class^="btn--"] {
    position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    min-width: 35%;
    text-align: center; }
  .collection.blocks .block.block-expertises .thumb img {
    max-width: 66%;
    margin: 0 auto; }
  .collection.blocks .block.block-expertises:hover {
    box-shadow: 0 0 0 2px #1a2732; }
    .collection.blocks .block.block-expertises:hover .button, .collection.blocks .block.block-expertises:hover .btn, .collection.blocks .block.block-expertises:hover a[class^="btn--"] {
      background: #1a2732; }

.section.expertise-overview .fullwidth .collection.blocks .max-items-1 {
  max-width: 100%; }

.section.expertise-overview .collection.blocks .column-row {
  margin-bottom: 50px; }
  .section.expertise-overview .collection.blocks .column-row:last-child {
    margin-bottom: 0; }
  .section.expertise-overview .collection.blocks .column-row:nth-child(even) .thumb {
    margin-right: 0;
    margin-left: 30px; }
  .section.expertise-overview .collection.blocks .column-row:nth-child(even) .content-wrapper {
    -ms-flex-order: -1;
        order: -1; }
  .section.expertise-overview .collection.blocks .column-row:last-child .block.big-expertises {
    padding-bottom: 0; }
    .section.expertise-overview .collection.blocks .column-row:last-child .block.big-expertises:after {
      display: none; }

.collection.blocks .block.big-expertises {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: row wrap;
      flex-flow: row wrap;
  -ms-flex-align: center;
      align-items: center;
  position: relative;
  padding-bottom: 50px; }
  .collection.blocks .block.big-expertises:after {
    content: "";
    height: 4px;
    background: #1a2732;
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 270px;
    margin-left: -135px; }
  .collection.blocks .block.big-expertises .thumb {
    -ms-flex: 0 1 25%;
        flex: 0 1 25%;
    margin-right: 30px; }
    .collection.blocks .block.big-expertises .thumb img {
      max-width: 110px;
      margin: 0 auto; }
  .collection.blocks .block.big-expertises .content-wrapper {
    -ms-flex: 1;
        flex: 1; }
    .collection.blocks .block.big-expertises .content-wrapper .block-title {
      margin-bottom: 15px; }

.section.cta-section {
  position: relative;
  margin-bottom: 50px !important; }
  .section.cta-section .section-wrapper {
    padding-bottom: 0 !important; }
  .section.cta-section:before {
    content: "";
    background-image: url("../images/cta-bg.jpg");
    background-size: cover;
    background-position: center center;
    height: 100%;
    display: block;
    width: 50%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1; }
  .section.cta-section .column-wrapper {
    -ms-flex-align: center;
        align-items: center; }
  .section.cta-section .column {
    position: relative;
    z-index: 2;
    margin: 0;
    -ms-flex: 0 0 50%;
        flex: 0 0 50%; }
  .section.cta-section .column.content h1, .section.cta-section .column.content h2, .section.cta-section .column.content h3, .section.cta-section .column.content h4, .section.cta-section .column.content h5, .section.cta-section .column.content h6, .section.cta-section .column.content p {
    color: #FFF; }
  .section.cta-section .column.gf-column, .section.cta-section .column.content:last-child {
    background: #1a2732;
    margin: 50px 0;
    box-sizing: border-box; }
    .section.cta-section .column.gf-column .inner-container, .section.cta-section .column.content:last-child .inner-container {
      padding: 50px; }
    .section.cta-section .column.gf-column .column-title, .section.cta-section .column.content:last-child .column-title {
      font-size: 18px;
      font-size: 1.125rem; }
    .section.cta-section .column.gf-column h1, .section.cta-section .column.gf-column h2, .section.cta-section .column.gf-column h3, .section.cta-section .column.gf-column h4, .section.cta-section .column.gf-column h5, .section.cta-section .column.gf-column h6, .section.cta-section .column.gf-column p, .section.cta-section .column.content:last-child h1, .section.cta-section .column.content:last-child h2, .section.cta-section .column.content:last-child h3, .section.cta-section .column.content:last-child h4, .section.cta-section .column.content:last-child h5, .section.cta-section .column.content:last-child h6, .section.cta-section .column.content:last-child p {
      color: #FFF; }
    .section.cta-section .column.gf-column .gform_footer, .section.cta-section .column.content:last-child .gform_footer {
      text-align: right; }
    .section.cta-section .column.gf-column .button:hover, .section.cta-section .column.gf-column .btn:hover, .section.cta-section .column.gf-column a[class^="btn--"]:hover, .section.cta-section .column.gf-column .button:focus, .section.cta-section .column.gf-column .btn:focus, .section.cta-section .column.gf-column a[class^="btn--"]:focus, .section.cta-section .column.content:last-child .button:hover, .section.cta-section .column.content:last-child .btn:hover, .section.cta-section .column.content:last-child a[class^="btn--"]:hover, .section.cta-section .column.content:last-child .button:focus, .section.cta-section .column.content:last-child .btn:focus, .section.cta-section .column.content:last-child a[class^="btn--"]:focus {
      background: #ffefc3;
      color: #1a2732; }

.section.expertise-payoff .container {
  max-width: 1440px; }

.section.expertise-payoff .section-title {
  color: #21a1b7;
  font-family: "Oswald", serif;
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  font-size: 36px;
  font-size: 2.25rem; }

.section.expertise-payoff .column {
  text-align: center;
  background: #ffefc3;
  padding: 20px 10px 20px 29px;
  position: relative;
  margin: 0;
  min-height: 58px; }
  .section.expertise-payoff .column span {
    display: none; }
  .section.expertise-payoff .column .column-title {
    font-size: 14px;
    font-size: 0.875rem;
    color: #444444;
    margin: 0;
    font-weight: 700; }
  .section.expertise-payoff .column:after {
    content: "";
    width: 0;
    height: 0;
    border-top: 29px solid transparent;
    border-bottom: 29px solid transparent;
    border-left: 29px solid #ffefc3;
    position: absolute;
    top: 0;
    right: -28px;
    z-index: 2; }
  .section.expertise-payoff .column:nth-child(2) {
    background: #e5c99d; }
    .section.expertise-payoff .column:nth-child(2):after {
      border-left-color: #e5c99d; }
  .section.expertise-payoff .column:nth-child(3) {
    background: #21a1b7; }
    .section.expertise-payoff .column:nth-child(3):after {
      border-left-color: #21a1b7; }
  .section.expertise-payoff .column:nth-child(4) {
    background: #1a2732; }
    .section.expertise-payoff .column:nth-child(4):after {
      display: none; }
    .section.expertise-payoff .column:nth-child(4) .column-title {
      color: #FFF; }

.flexbox.no-flexboxlegacy header.scrolled-state .logo {
  top: -50px; }

.flexbox.no-flexboxlegacy .big-header .container {
  position: relative; }

.flexbox.no-flexboxlegacy .big-header .container .right-side {
  position: absolute;
  top: 50%;
  width: 50%;
  right: 0;
  transform: translateY(-50%); }

.flexbox.no-flexboxlegacy .big-header .container .left-side {
  position: absolute;
  bottom: 0;
  width: 50%;
  left: 0; }

@media only screen and (max-width: 768px) {
  body {
    font-size: 15px;
    font-size: 0.9375rem; }
  h1 {
    font-size: 26px;
    font-size: 1.625rem; }
  .section.expertise-payoff .section-title, h2 {
    font-size: 20px;
    font-size: 1.25rem; }
  h3, .section.cta-section .column.gf-column .column-title {
    font-size: 15px;
    font-size: 0.9375rem; }
  h4 {
    font-size: 15px;
    font-size: 0.9375rem; }
  h5 {
    font-size: 14px;
    font-size: 0.875rem; }
  h6 {
    font-size: 13px;
    font-size: 0.8125rem; }
  .intro p {
    font-size: 17px;
    font-size: 1.0625rem; }
  .column.featured-column .repeater-item {
    padding: 15px;
    margin-bottom: 30px; }
  #main .section:first-child .section-wrapper {
    padding-top: 30px; }
  #main .section .section-wrapper {
    padding: 0 0 30px 0; }
  .section.cta-section .column.gf-column, .section.cta-section .column.content:last-child {
    margin: 30px 0; }
    .section.cta-section .column.gf-column .inner-container, .section.cta-section .column.content:last-child .inner-container {
      padding: 15px; }
  .column.gf-column input[type=text], .column.gf-column textarea {
    font-size: 15px;
    font-size: 0.9375rem; }
  .collection.blocks .block.block-expertises .thumb img {
    max-width: 110px; }
  .button, .btn, a[class^="btn--"], .btn, a[class^="btn--"], .button:visited, .btn:visited, a[class^="btn--"]:visited, .btn:visited, a[class^="btn--"]:visited {
    font-size: 15px;
    font-size: 0.9375rem; } }

@media only screen and (max-width: 1220px) {
  header .logo {
    left: 4%; } }

@media only screen and (max-width: 1120px) {
  .big-header .container {
    min-height: 500px; }
  .big-header {
    background-attachment: scroll; } }

@media only screen and (max-width: 1024px) {
  .menu-switch {
    box-sizing: border-box;
    background: #FFF; }
    .menu-switch i {
      color: #1a2732; }
    .menu-switch .mobile-button-text {
      color: #FFF;
      left: -48px; }
  header .container {
    min-height: 65px; }
  header {
    background: #1a2732; }
  header .logo, header.scrolled-state .logo {
    max-width: 145px;
    top: 0px;
    background: #21a1b7;
    padding: 10px;
    height: 80px;
    overflow: hidden; }
  .mobile-nav ul li a, .mobile-nav ul li a:visited {
    box-sizing: border-box; }
  .big-header {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: row wrap;
        flex-flow: row wrap;
    -ms-flex-align: end;
        align-items: flex-end;
    min-height: 400px; }
    .big-header .container {
      min-height: auto; }
  .big-header .container {
    -ms-flex-align: inherit;
        align-items: inherit; }
    .big-header .container .left-side {
      -ms-flex: 0 0 100%;
          flex: 0 0 100%;
      -ms-flex-item-align: inherit;
          -ms-grid-row-align: inherit;
          align-self: inherit; }
    .big-header .container .right-side {
      -ms-flex: 0 0 100%;
          flex: 0 0 100%;
      -ms-flex-order: -1;
          order: -1;
      margin-bottom: 30px; }
      .big-header .container .right-side .payoff-area {
        margin-left: 0; } }

@media only screen and (max-width: 980px) {
  .big-header .container .right-side .payoff-area {
    padding: 30px 0px 40px 30px;
    font-size: 38px;
    font-size: 2.375rem; }
  .big-header .container .left-side .header-btn, .big-header .container .left-side .header-btn:visited {
    font-size: 16px;
    font-size: 1rem; } }

@media only screen and (max-width: 768px) {
  .big-header .container .right-side .payoff-area {
    padding: 15px 0px 20px 15px;
    font-size: 30px;
    font-size: 1.875rem; }
  .big-header .container .left-side .header-btn, .big-header .container .left-side .header-btn:visited {
    font-size: 14px;
    font-size: 0.875rem;
    padding: 10px 80px 10px 15px; }
    .big-header .container .left-side .header-btn:before, .big-header .container .left-side .header-btn:visited:before {
      width: 50px; }
    .big-header .container .left-side .header-btn:after, .big-header .container .left-side .header-btn:visited:after {
      width: 50px; }
  .big-header {
    min-height: 300px; } }

@media only screen and (max-width: 480px) {
  a[class^="mustache"] {
    position: initial;
    margin: 0 auto 10px auto; }
  .privacy-policy {
    float: none;
    width: 100%;
    text-align: center;
    display: inline-block;
    margin-bottom: 10px; }
  .footer .copyright .container {
    text-align: center; } }

@media only screen and (max-width: 1480px) {
  .section.expertise-payoff .column .column-title {
    font-size: 12px;
    font-size: 0.75rem; }
  .section.expertise-payoff .container {
    max-width: 1280px; } }

@media only screen and (max-width: 1320px) {
  .section.expertise-payoff .container {
    max-width: 1170px; } }

@media only screen and (max-width: 1220px) {
  .section.expertise-payoff .column:after {
    display: none; } }

@media only screen and (max-width: 1024px) {
  .section.expertise-payoff .column {
    -ms-flex: 0 1 50%;
        flex: 0 1 50%;
    margin: 0 !important; } }

@media only screen and (max-width: 768px) {
  .section.cta-section:before {
    display: none; }
  .section.cta-section .column.content:first-child {
    padding: 60px 15px;
    background-image: url(../images/cta-bg.jpg);
    background-size: cover;
    background-position: center center; } }

@media only screen and (max-width: 600px) {
  .collection.blocks .block.big-expertises .thumb {
    -ms-flex: 0 0 100%;
        flex: 0 0 100%;
    -ms-flex-order: -1 !important;
        order: -1 !important;
    margin: 0 0 15px 0 !important; }
  .collection.blocks .block.big-expertises .content-wrapper {
    -ms-flex: 0 0 100%;
        flex: 0 0 100%;
    margin: 0; } }

/*# sourceMappingURL=main.css.map */
