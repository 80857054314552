/**
* Textual CSS file 
* Styling for basic typography:
* 
*/

h1,h2,h3,h4,h5,h6{
	color:$black;
	font-family:$head-stack;
	line-height: 135%;
}

p, a, span, ol, ul, li, strong, em, i, b, big, small, tt, abbr, acronym, 
cite, code, dfn, em, kbd, samp, var, br, sub, sup, button, input, label,
select, textarea{
	color:$black;
	font-family:$font-stack;
	line-height: 135%;
}

h1{font-size:44px;}
h2{font-size:38px;}
h3{font-size:28px;}
h4{font-size:24px;}
h5{font-size:18px;}
h6{font-size:14px;}

.column{
	h1,h2,h3,h4,h5,h6{
		margin:30px 0 15px 0;
		&:first-child{
			margin-top:0;
		}
	}
	.column-title{
		margin:0 0 15px 0;
	}
}
