/**
 * Variable file 
 * place global variables here:
 *
 * @package Boxss
 */

@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700|Oswald:700);

$font-stack:'Open Sans', sans-serif;
$head-stack:'Open Sans', serif;
$special-stack:'Oswald', serif;

$lightBlue: #21a1b7;
$darkBlue: #1a2732;
$yellow: #ffefc3;
$beige: #e5c99d;

$white: #fff;
$black: $darkBlue;
$grey: #444444;
$lightGrey: #e3e3e3;

$border-radius: 4px;
$form-input-color: #e1e1e1;
$errorColor: #ae2d30;

$light-weight:300;
$regular-weight: 400;
$semibold-weigiht: 600;
$bold-weight: 700;