@media only screen and ( max-width: 1024px ){
	header{
		.main-nav, .top-nav{
			display: none;
		}
	}
	.mobile-nav{
		display: block;
	}
	.background-wrapper{
		display: block;
	}
	.menu-switch{
		display: inline-block;
	}
}