body{
	@include font-size( 16px );
}

h1, h2, h3, h4, h5, h6{
	font-weight:$bold-weight;
	color:$lightBlue;
}

h1{
	@include font-size( 30px );
}

h2{
	@include font-size( 24px );
}

h3{
	@include font-size( 20px );
}

h4{
	@include font-size( 18px );
}

h5{
	@include font-size( 17px );
}

h6{
	@include font-size( 16px );
}

em{
	font-style:italic;
}


.button, .button:visited{
	@include button();
}

.button-wrapper{
	text-align:center;
	margin-top:30px;
}

.intro{
	p{
		font-style:italic;
		font-weight:$light-weight;
		color:$lightBlue;
		@include font-size( 20px );
	}
}

.column{
	h1, h2, h3, h4, h5, h6, p, .intro{
		margin-bottom:15px;
		&:last-child{
			margin-bottom:0;
		}
	}
}

.section .section-title{
	margin-bottom:30px;
}

.section .anchor{
	position:relative;
	top:-100px;
	display: inline-block;
	height:0;
	width:0;
}