// .form .loader > div{
// 	background: $form-input-color;
// }

// .form.msg .message{
// 	background: $form-input-color;
// }

// .form-footer .submit-form{
// 	@include button();
// }

.gfield_label{
	@include remove-labels();
}

.column.gf-column{
	ul{
		li{
			margin-bottom:15px;
			position:relative;
			&:last-child{
				margin-bottom:0;
			}
		}
	}
	.ginput_container{
		position:relative;
	}
	input[type=text], textarea{
		padding:7px 14px;
		display:inline-block;
		width:100%;
		box-sizing:border-box;
		@include font-size( 16px );
		border:2px solid #FFF;
		&:focus{
			outline:none;
			border-color:$lightBlue;
		}
	}
	textarea{
		overflow-y:auto;
	}
	.gform_footer{
		margin-top:30px;
	}

	form > .validation_error{
		color:$errorColor;
		border:2px solid $errorColor;
		border-left:none;
		border-right:none;
		margin-bottom:15px;
		padding:15px 20px;
	}

	.gfield_error{
		position:relative;
		z-index: 2;
	}

	.gfield_error .validation_message{
		color:#FFF;
		border-color:$errorColor;
		position: absolute;
		top: 16px;
		left: 0;
		bottom:auto;
		background:$errorColor;
		padding:2px 4px;
		display:inline-block;
		width:auto;
		transform: translateY( 100% );
		z-index: 10;
		@include font-size( 10px );

		&:before{
			content:"";
			width: 0; 
			height: 0; 
			border-left: 6px solid transparent;
			border-right: 6px solid transparent;

			border-bottom: 6px solid $errorColor;
			position:absolute;
			left:50%;
			top:-6px;
			margin-left:-6px;
			z-index: 11;
		}
	}

	.validation_message{

	}

	.gform_wrapper.gform_validation_error .gform_body ul li.gfield.gfield_error:not(.gf_left_half):not(.gf_right_half){
		max-width:100%;
	}
	
	.gform_wrapper ul.gform_fields li.gfield{
		padding-right:0;
	}
	.gfield_checkbox{
		input[type=checkbox] {
		    display: none;
		}
		input[type=checkbox]:checked + label:after {
		    opacity:1;
		}
		label{
			display: inline-block;
			cursor: pointer;
			position: relative;
			padding-left: 30px;
			margin-right: 15px;
			margin-bottom:10px;
			color:#FFF;

			&:hover{
				&:before{
					background: $lightBlue;
				}
			}

			a, a:visited{
				color:#FFF;
				transition:.3s;
				&:hover{
					color:$lightBlue;
				}
			}
		}
		label:before {
		    content: "";
		    display: inline-block;
		 	transition: .3s;
		    width: 20px;
		    height: 20px;
		    margin-right: 10px;
		    position: absolute;
		    left: 0;
		    bottom: 0;
		    background: #FFF;
		}
		label:after {
		    content: "\2713";
		 	transition: .3s;
		    width: 20px;
		    height: 20px;
		    position: absolute;
		    left: 0;
		    bottom:0px;
		    padding-bottom:8px;
		    text-align:center;
		    display: inline-block;
		    color: #000;
		    opacity:0;
		    font-size:30px;
		    line-height:100%;
		}
	}

}



