.flexbox.no-flexboxlegacy{
	header.scrolled-state .logo{
		top:-50px;
	}
	.big-header .container{
		position:relative;
	}
	.big-header .container .right-side{
		position:absolute;
		top:50%;
		width:50%;
		right:0;
		transform:translateY( -50% );
	}
	.big-header .container .left-side{
		position:absolute;
		bottom:0;
		width:50%;
		left:0;
	}
}