//list:
.collection.list{
	.block-row{ 
		display: flex;
		flex-flow: row wrap;
		width:100% !important;
	}
	
	.list-block, .list-block:visited{
		flex: 0 0 100%;
		transition: .3s;
		cursor: pointer;
		text-decoration: none;

		&:last-of-type{
			margin:0;
		}
	}
}

.section .fullwidth .collection.list{
	.list-block, .list-block:visited{
		flex: 0 0 49%;
		margin-right:2%;
		
		&:nth-of-type(even){
			margin-right:0;
		}
		&:nth-last-child(-n+2){
			margin-bottom:0;
		}
	}
}

//regular
.collection{
	flex:0 0 100%;
}
.collection.blocks{
	.column-row{
		flex-flow: row wrap;
		margin-bottom:30px;
		display: flex;
		&:last-of-type{
			margin-bottom:0 !important;
		}
		.block, .block:visited{
			flex: 1;
			box-sizing:border-box;
		}
		&.max-items-6{
			.block{
				flex: 0 1 32%;
				&:nth-child( 3n+3 ){
					margin-right:0;
				}
				&:nth-child(-n+3){
					margin-bottom:25px;
				}
			}
		}
		&.max-items-4{
			.block{
				flex: 0 1 23.5%;
			}
		}
		&.max-items-3{
			.block{
				flex: 0 1 32%;
			}
		}
		&.max-items-2{
			.block{
				flex: 0 1 49%;
			}
		}
		&.max-items-1{
			.block{
				flex: 0 1 100%;
			}
		}
	}
	.block, .block:visited{
		flex: 1;
		margin-right:2%;
		text-decoration: none;
		&:last-of-type{
			margin-right:0;
		}
		
		&:last-of-type{
			margin-right:0;
		}
	}
}

.section .fullwidth{
	.collection.blocks{
		.max-items-1{
			max-width:770px;
			margin-left:auto;
			margin-right:auto;
		}
	}
}

//loader:
.collection{ 
	position: relative;
	transition: .3s;
	&.loading{
		opacity:0.6;

		.loader{
			opacity: 1;
		}
	}
	.loader{
		opacity:0;
		position: absolute;
		left:50%;
		top:50%;
		transform: translateY(-50%) translateX(-50%);
		transition: .3s;
		z-index: 4;
		
		> div{
			background: $black;
			border-radius:0;
		}
	}
}

//masonry:
.collection.blocks.masonry{  
	flex: none;
	display: block;
	width:100%;
	.block{
		flex: none;
		width:32%;
		margin-bottom:15px;
		display: block;
		margin-right:0 !important;
	}
}