.section.cta-section{
	position:relative;
	margin-bottom:50px !important;
	.section-wrapper{
		padding-bottom:0 !important;
	}
	&:before{
		content:"";
		background-image:url('../images/cta-bg.jpg');
		background-size:cover;
		background-position:center center;
		height:100%;
		display:block;
		width:50%;
		position:absolute;
		left:0;
		top:0;
		z-index: 1;
	}
	.column-wrapper{
		align-items:center;
	}
	.column{
		position:relative;
		z-index: 2;
		margin:0;
		flex:0 0 50%;
	}
	.column.content{
		h1, h2, h3, h4, h5, h6, p{
			color:#FFF;
		}
		.column-title{
			//font-family:$special-stack;
		}
	}
	.column.gf-column, .column.content:last-child{
		background:$darkBlue;
		margin:50px 0;
		box-sizing:border-box;
		.inner-container{
			padding:50px;
		}
		.column-title{
			@include font-size( 18px );
		}
		h1, h2, h3, h4, h5, h6, p{
			color:#FFF;
		}
		.gform_footer{
			text-align:right;
		}
		.button{
			&:hover, &:focus{
				background:$yellow;
				color:$darkBlue;
			}
		}
	}
}

.section.expertise-payoff{
	.container{
		max-width:1440px;
	}
	.section-title{
		color:$lightBlue;
		font-family:$special-stack;
		width:100%;
		text-align:center;
		text-transform:uppercase;
		@include font-size( 36px );
	}
	.column{
		text-align:center;
		background:$yellow;
		padding: 20px 10px 20px 29px;
		position:relative;
		margin:0;
		min-height:58px;
		span{
			display:none;
		}
		.column-title{
			@include font-size( 14px );
			color:$grey;
			margin:0;
			font-weight:$bold-weight;
		}
		&:after{
			content:"";
			width: 0; 
			height: 0; 
			border-top: 29px solid transparent;
			border-bottom: 29px solid transparent; 

			border-left:29px solid $yellow; 
			position:absolute;
			top:0;
			right:-28px;
			z-index: 2;
		}
		&:nth-child(2){
			background:$beige;
			&:after{
				border-left-color:$beige;
			}
		}
		&:nth-child(3){
			background:$lightBlue;
			&:after{
				border-left-color:$lightBlue;
			}
		}
		&:nth-child(4){
			background:$darkBlue;
			&:after{
				display:none;
			}
			.column-title{
				color:#FFF;
			}
		}
	}
}